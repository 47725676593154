import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getContent } from "services/apiService";
import Classes from "./StaticPage.module.scss";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomer } from "store/selectors";
import LoadingIndicator from "components/common/LoadingIndicator";
import ReactHtmlParser from "react-html-parser";
import ContentFile from "components/common/ContentFile";
import ImagesCarousel from "components/common/ImagesCarousel";
import { getContentByLanguage } from "helpers/util";

const StaticPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const customer = useSelector(getCurrentCustomer);
  const { i18n } = useTranslation();
  const [content, setPageContent] = useState(null);
  const [pending, setPending] = useState(false);

  const description = getContentByLanguage(content, "description");

  const params = new URLSearchParams(search);
  const pageName = params.get("pageName");
  const pageId = params.get("pageId");

  useEffect(() => {
    const fetchData = async () => {
      const response = await getContent(
        {
          content_id: pageId,
          customer_id: customer?.customer_id,
        },
        dispatch
      );

      console.log(response)
      setPageContent(response || null);
      setPending(false);
    };

    setPending(true);
    fetchData();
  }, [pageId, customer, dispatch]);

  const elements = useMemo(() => {
    if(!content?.images?.reduce){
      return { images: [], files: [] }
    }

    return content?.images?.reduce(
      (acc, item) => {
        if (item.mime_type === "application/pdf") {
          acc.files.push(item);
        } else if (
          !item.language_code ||
          item.language_code === i18n.language
        ) {
          acc.images.push(item);
        }

        return acc;
      },
      { images: [], files: [] }
    );
  }, [content, i18n.language]);

  return (
    <div className={Classes.container}>
      <div className={Classes.header}>
        <h3>{decodeURIComponent(pageName)}</h3>
      </div>
      {pending && <LoadingIndicator />}
      {!!content && (
        <>
          {elements?.files?.length > 0 && (
            <div>
              {elements.files.map((item) => (
                <ContentFile {...item} key={item.content_image_id} />
              ))}
            </div>
          )}

          {content && (
            <div className={Classes.content}>
              {ReactHtmlParser(description)}
            </div>
          )}

          {elements?.images?.length > 0 && (
            <ImagesCarousel
              images={elements.images}
              className={Classes.image}
              imageId="content_image_id"
            />
          )}
        </>
      )}
      {!pending && !content && (
        <div className={Classes.noResult}>No info...</div>
      )}
    </div>
  );
};

export default StaticPage;
