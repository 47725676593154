import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getQuizByCustomer, getQuizContents } from "services/apiService";
import {
  getAccount,
  getCurrentCustomer,
  getOfflineStatus,
} from "store/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faLock, faTrophy } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./Main.module.scss";
import { fillLevels } from "../helper";

const levelsInitial = fillLevels();

const LevelItem = ({ level, enabled }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => history.push(`/game/quiz/${level}`);

  return (
    <div
      className={cx(Classes.level, enabled ? "" : Classes.disabled)}
      onClick={onClick}
    >
      {enabled ? (
        ""
      ) : (
        <div className={Classes.lock}>
          <FontAwesomeIcon icon={faLock} />
        </div>
      )}
      <div className={Classes.logo}>
        <FontAwesomeIcon icon={faAward} size="4x" />
      </div>
      <div className={Classes.text}>
        {t("level")} {level}
      </div>
    </div>
  );
};

const QuizStaticPage = ({ page }) => (
  <div className={Classes.page}>
    <Link to={`/game/content/${page.quiz_content_id}`}>{page.name}</Link>
  </div>
);

const Leaderboard = () => {
  const { t } = useTranslation();

  return (
    <div className={Classes.leaderboard}>
      <Link to="/game/leaderboard">
        <h3>
          <FontAwesomeIcon icon={faTrophy} />
          &nbsp; {t("leaderboards")}
        </h3>
      </Link>
    </div>
  );
};

const MenuItems = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCurrentCustomer);
  const offline = useSelector(getOfflineStatus);

  const account = useSelector(getAccount);
  const [levels, setLevels] = useState(levelsInitial);
  const [pages, setPages] = useState([]);
  const { t } = useTranslation();

  const customerGameEnabled = customer?.game_enabled;
  const accountGameEnabled = customer?.customer_has_members?.[0]?.game_enabled;
  const isGameEnabled = accountGameEnabled && customerGameEnabled;

  const fetchLevels = async (customerId) => {
    if (offline) {
      return;
    }

    const response = await getQuizByCustomer(customerId, account, dispatch);

    setLevels(fillLevels(response));
  };

  const fetchQuizContents = async (customerId) => {
    if (offline) {
      return;
    }

    const params = {
      customer_id: customerId,
      type: "STATIC_PAGE",
    };
    const response = await getQuizContents(params, dispatch);
    setPages(response?.rows);
  };

  useEffect(() => {
    const customerId = Number(customer?.customer_id);

    if (customerId) {
      fetchLevels(customerId);
      fetchQuizContents(customerId);
    }
  }, [customer, dispatch]);

  return (
    <div className={Classes.container}>
      <h3 className={Classes.header}>{t("game")}</h3>
      {isGameEnabled ? <Leaderboard /> : ""}
      <div className={Classes.levels}>
        {isGameEnabled
          ? levels.map((data, index) => (
              <LevelItem key={index} {...data} enabled={!data.locked} />
            ))
          : [1, 2, 3].map((level, index) => (
              <LevelItem key={index} level={level} />
            ))}
      </div>
      {!!(isGameEnabled && pages?.length > 0) && (
        <div className={Classes.staticPages}>
          {pages?.map((page, index) => (
            <QuizStaticPage key={index} page={page} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItems;
