import Answers from "./Answers";
import ImagesCarousel from "components/common/ImagesCarousel";

import Classes from "./MultiQuestion.module.scss";
import { getContentByLanguage } from "helpers/util";

const MultiQuestion = ({ question, takenAnswer, ...props }) => (
  <div className={Classes.container}>
    {!!question.images?.length && (
      <ImagesCarousel
        images={question.images}
        imageId="question_image_id"
        containerClassName={Classes.images}
        className={Classes.image}
      />
    )}
    <div className={Classes.text}>
      {getContentByLanguage(question, "text")}
    </div>
    <Answers
      {...props}
      question={question}
      takenAnswer={takenAnswer}
      answers={question.answers}
    />
  </div>
);

export default MultiQuestion;
