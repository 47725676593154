import Selector from "components/common/Selector";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const LevelSelector = ({ setValue, value, list }) => {
  const [selected, setSelected] = useState(null);
  const reference = useRef(null);
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      list.reduce((acc, lvl) => {
        acc[lvl.level] = {
          label: t("level") + " " + lvl.level,
          value: lvl.level,
          disabled: lvl.locked,
        };
        return acc;
      }, {}),
    [list, t]
  );

  useEffect(() => {
    if (reference.current !== value && Object.values(options).length > 0) {
      setSelected(value ? options[value] : options["1"]);
      reference.current = value;
    }
  }, [value, options, t]);

  const handleSelectChange = (selectedOption) => {
    setSelected(selectedOption);
    setValue(selectedOption.value);
    reference.current = selectedOption.value;
  };

  return (
    <Selector
      value={selected ?? options["1"]}
      onChange={handleSelectChange}
      options={Object.values(options)}
      isOptionDisabled={(option) => option.disabled}
    />
  );
};

export default LevelSelector;
