import React from "react";

import cx from 'classnames';
import Classes from "./Container.module.scss";

const Container = ({ children, type }) => (
  <div className={cx(Classes.container, Classes[type])}>
    {children}
  </div>
);

export default Container;
