import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { logout } from "store/actionCreators/authentication";

const useLogout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/logout") {
      dispatch(logout());
    }
  }, [location]);

  return false;
};

export default useLogout;
