import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMessages } from "store/selectors";

import ConversationsItem from "./ConversationsItem";
import NewMessage from "./NewMessage";

import Classes from "./Messages.module.scss";

const Empty = () => {
  const { t } = useTranslation();

  return <div className={Classes.emptyList}>{t("noConversations")}</div>;
};

function Messages() {
  const messages = useSelector(getMessages);

  return (
    <div className={Classes.container}>
      <div className={Classes.conversations}>
        {messages.map((msg, index) => (
          <ConversationsItem key={index} {...msg} />
        ))}
        {!messages.length && <Empty />}
      </div>
      <NewMessage />
    </div>
  );
}

export default Messages;
