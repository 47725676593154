import vs from "./ValidationService";

class GlobalValidator {
  static validateAuthentication(params) {
    let error = {};
    let errorEmail = vs.isEmail(params.email, true);
    if (errorEmail) {
      error.email = errorEmail;
    }
    // let errorPassword = vs.isPassword(params.password, true);
    // if (errorPassword) {
    //   error.password = errorPassword;
    // }

    let success = Object.keys(error).length;
    return { success: !success, error: error };
  }
}

export default GlobalValidator;
