import React, { useState } from "react";

import { faCommentDots, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Recipient from "../Recipient";

import cx from "classnames";
import Classes from "./NewMessage.module.scss";

const NewMessage = () => {
  const [expanded, setExpanded] = useState(false);

  const onToggle = (elem, event) => {
    event?.stopPropagation();
    setExpanded(current => !current);
  }

  return (
    <div className={cx(Classes.container)}>
      <div onClick={onToggle} className={cx(Classes.newMessage, expanded ? Classes.expanded : "")}>
        <FontAwesomeIcon size="2x" icon={expanded ? faTimes : faCommentDots} />
      </div>
      {expanded && <Recipient onClose={onToggle} />}
    </div>
  );
};

export default NewMessage;
