export const saveUser = (data) => {
  localStorage.authenticationData = JSON.stringify(data);
  // We do not send expiration field in auth response for now.
  // localStorage.expDate = (new Date()).getTime() / 1000 + data.authenticationData[0].exp;
};

export const clearToken = () => {
  localStorage.authenticationData = "";
  localStorage.expDate = "";
};

export const getAuthenticationToken = () => {
  const authData = localStorage.getItem('authenticationData');

  if (authData) {
    return JSON.parse(authData)?.token;
  }

  return null;
}

