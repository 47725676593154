const configs =  {
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:3533/api",
  adminUrl: process.env.REACT_APP_ADMIN_URL || "http://localhost:3000",
  GWT_CUSTOMER_ID: 6,
  fetchIntervals: {
    messages: 10000,
  },
};

export default configs
