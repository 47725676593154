// Components
import Plants from "components/Plants";
import FullImageOptions from "./FullImageOption";
import DefaultOptions from "./DefaultOption";
import Container from "./Container";
import { SCREEN_TYPES } from "../constants";

const Screen = ({ currentScreen, options, type }) => {

  if (currentScreen === null) {
    return <Plants />;
  }

  if (![SCREEN_TYPES.default, SCREEN_TYPES.home].includes(type)) {
    return '';
  }

  let Options = DefaultOptions;
  if (type === 'home') {
    Options = FullImageOptions;
  }

  return (
    <Container type={type} >
      {options.map((option, index) => (
        <Options
          key={index}
          index={index}
          {...option}
          currentScreen={currentScreen}
        />
      ))}
    </Container>
  );
};

export default Screen;
