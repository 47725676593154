import { useMemo } from "react";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { retakeQuiz } from "services/apiService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faMeh } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./Summary.module.scss";

const FailedSummary = ({ quiz_taken_id, passing_score, score, content, level }) => {
    const dispatch = useDispatch();

    const handleRetake = async () => {
        const params = { quiz_taken_id };
          await retakeQuiz(params, dispatch);

          // reload
          window.location.reload();
    }

    const contentImage = useMemo(() => {
        const image = content?.images?.find(item => item.mime_type.includes('image'));

        if (image?.img_uri_lowRes) {
            return (
                <div className={Classes.customImage} >
                    <img src={image.img_uri_lowRes} alt={image.title} />
                </div>
            );
        }

        return (
            <div className={Classes.imageContainer} >
                <div className={Classes.logo} >
                    <FontAwesomeIcon size="3x" icon={faMeh} />
                </div>
            </div>
        );
    }, [content]);

    return (
        <div className={Classes.summary}>
            {contentImage}
            <div className={cx(Classes.header, Classes.fail)} >
                {content?.name || `Level ${level} Failed`}
            </div>
            <div className={Classes.score} >
                Passing Score {score || 0} / {passing_score || 0}
            </div>
            <div className={Classes.description} >
                {content?.description && ReactHtmlParser(content.description)}
            </div>
            <div className={Classes.actions}>
                <button onClick={handleRetake} className={Classes.button} type="submit">
                    <FontAwesomeIcon icon={faRedo} />
                    <span>Try again</span>
                </button>
            </div>
        </div>
    )
}

export default FailedSummary;
