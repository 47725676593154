import React from "react";

import PortalNode from "components/common/PortalNode";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import Classes from './Actions.module.scss';

const Actions = ({ onClose, message, onClick }) => {
  if (!message) {
    return '';
  }

  return (
    <PortalNode onClick={onClose.bind(null, null)} >
      <div className={Classes.container} onClick={onClick}>
        <div className={Classes.forward}>
          <span>Forward message</span>
          <FontAwesomeIcon icon={faPaperPlane}  />
        </div>
      </div>
    </PortalNode>
  );
};

export default Actions;
