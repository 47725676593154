import {
  take,
  fork,
  put,
  call,
  delay,
  select,
  cancel,
} from "redux-saga/effects";
import { callApi } from "./callApi";
import config from "../../config/config";
import actionTypes from "../actionTypes";
import {
  getAuthenticationStatus,
  getAccount,
  getCurrentCustomer,
  getOfflineStatus,
} from "../selectors";
import { successMessages } from "store/actionCreators/messages";
import { createCallAxios, methods } from "services/apiService";
import { url } from "helpers/util";

function* getMessages() {
  try {
    const { member_id } = yield select(getAccount);
    const { customer_id } = yield select(getCurrentCustomer);

    const { data } = yield call(createCallAxios, url("getMessages"), methods.GET,null, {
      member_id,
      customer_id,
    });

    yield put(successMessages({ messages: data.result }));
  } catch (e) {}
}

export function* fetchMessagesByInterval() {
  while (true) {
    const isAuthenticated = yield select(getAuthenticationStatus);
    const offline = yield select(getOfflineStatus);
    if (isAuthenticated && !offline) {
      yield fork(getMessages);
    }
    yield delay(config.fetchIntervals.messages);
  }
}

export function* watchLoginToFetchMessages() {
  while (true) {
    yield take(actionTypes.LOGIN_SUCCESS);
    yield take([actionTypes.LOGIN_SUCCESS, actionTypes.GET_CUSTOMERS_SUCCESS]);
    yield fork(getMessages);
  }
}
