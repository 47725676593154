import React from "react";
import { useTranslation } from "react-i18next";
import { toCamelCase } from "helpers/util";
import ReactHtmlParser from "react-html-parser";
import ImagesCarousel from "components/common/ImagesCarousel";
import { getContentByLanguage } from "helpers/util";
import cx from "classnames";
import Classes from "./Plant.module.scss";

export const PlantSection = ({ section, index }) => {
  const { t } = useTranslation();
  const content = getContentByLanguage(section, "text");

  return content ? (
    <div key={index} className={Classes.sectionContainer}>
      {/* Section text goes here */}
      <div className={Classes.description}>
        {section.type !== "description" && (
          <div className={Classes.title}>{t(toCamelCase(section.type))}</div>
        )}
        {ReactHtmlParser(getContentByLanguage(section, "text"))}
      </div>
      <ImagesCarousel
        images={section.images}
        className={cx(
          Classes.sectionImageContainer,
          section.type === "treatment" ? Classes.treatment : ""
        )}
        imageId="section_image_id"
      />
    </div>
  ) : (
    ""
  );
};
