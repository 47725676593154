import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentCustomer } from "store/selectors";
import { getQuestionsByLevel } from "services/apiService";

import { LEVEL_STATUSES } from "../../constants";

const useQuestions = (quiz, quizTaken, fetchQuizTaken) => {
    const dispatch = useDispatch();
    const customer = useSelector(getCurrentCustomer);
    const [list, setList] = useState([]);
    const [questionsCount, setQuestionsCount] = useState([]);
    const [pending, setPending] = useState(null);
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [levelSummary, setLevelSummary] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(null);

    useEffect(() => setTimeoutRef(null), [quiz]);

    useEffect(() => setLevelSummary(quizTaken && quizTaken.status !== LEVEL_STATUSES.inProgress), [quizTaken])

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!customer?.customer_id || !quizTaken) {
                return;
            }

            const params = {
                level: Number(quizTaken.level),
                customer_id: Number(customer.customer_id),
                quiz_taken_id: Number(quizTaken?.quiz_taken_id),
                dispatch,
            }

            const response = await getQuestionsByLevel(params, dispatch);

            if (response) {

                let currentKey = '';
                const result = response.reduce((acc, item, index) => {

                    if (!(item.quiz_answer_id || item.is_skipped) && !currentKey) {
                        currentKey = `question-${item.quiz_question_id}`;
                        item.unanswered = true;
                    }

                    acc[`question-${item.quiz_question_id}`] = {
                        ...item,
                        index: index + 1,
                        nextQuestionId: response[index + 1]?.quiz_question_id,
                        prevQuestionId: response[index - 1]?.quiz_question_id,
                    };

                    return acc;
                }, { lastQuestionId: response[response.length - 1]?.quiz_question_id });

                if (!timeoutRef) {
                    setCurrentScreen(currentKey || 'summary');
                }

                setList(result);
            } else {
                setList({});
            }

            setQuestionsCount(response?.length || 0);
        }
        
        fetchQuestions();
    }, [customer, quizTaken, timeoutRef, dispatch]);

    const getSkippedCount = useCallback(() => {
        let skippedCount = 0;
        Object.values(list).forEach(l => {
            if (l.is_skipped && !l.quiz_answer_id) {
                ++skippedCount;
            }
        });

        return skippedCount;
    }, [list]);

    if (!customer || !quiz) {
        return '';
    }

    const onTabSelect = (k) => {
        if (pending) {
            return;
        }

        setCurrentScreen(k);
        clearTimeout(timeoutRef)
    }

    const loadQuestion = (nextQuestionId, immediately) => {
        fetchQuizTaken();

        if (immediately) {
            setCurrentScreen(nextQuestionId ? `question-${nextQuestionId}` : 'summary');
            setTimeoutRef(Math.random());
        } else {
            const timeoutId = setTimeout(() => {
                setCurrentScreen(nextQuestionId ? `question-${nextQuestionId}` : 'summary');
            }, 2000);  
  
            setTimeoutRef(timeoutId);
        }
    }

    return {
        currentScreen,
        list,
        pending,
        levelSummary,
        questionsCount,
        setPending,
        setList,
        onTabSelect,
        loadQuestion,
        getSkippedCount,
    };
}

export default useQuestions;
