import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { getAccounts } from "services/apiService";
import { getCurrentCustomer } from "store/selectors";

import Avatar from "components/common/Avatar";
import AccountName from "components/common/AccountName";
import PortalNode from "components/common/PortalNode";

import { FETCH_TIMEOUT } from "../constants";
import { ADMIN_ID } from "config/constants";

import Classes from "./Recipient.module.scss";

const Recipient = ({ onClose, withSendButton, onSend }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recipients, setRecipients] = useState([]);
  const [search, setSearch] = useState("");
  const timerRef = useRef(null);
  const customer = useSelector(getCurrentCustomer);
  const { t } = useTranslation();

  const onChange = (event) => {
    event.stopPropagation();
    const value = event.target.value;
    setSearch(value);
  };

  const onClick = (event, partnerId) => {
    event.stopPropagation();

    if (partnerId) {
      history.push(`/conversation/${partnerId}`);
    }
  };

  useEffect(() => loadOptions(search), [customer, search]);

  const loadOptions = (value) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    return new Promise((resolve) => {
      timerRef.current = setTimeout(async () => {
        const params = {
          name: value,
          exclude_requester: true,
          filterByCustomer: true,
          customer_id: customer?.customer_id,
        };
        const response = await getAccounts(params, dispatch);
        setRecipients(response ?? []);
        resolve(response, FETCH_TIMEOUT);
      }, FETCH_TIMEOUT);
    });
  };

  const stopBubbling = (event) => {
    event.stopPropagation();
  };

  return (
    <PortalNode onClick={onClose.bind(null, null)}>
      <div className={Classes.container} onClick={stopBubbling}>
        <div className={Classes.search}>
          <input
            type="text"
            value={search}
            placeholder={t("recipientName")}
            onChange={onChange}
          />
        </div>
        <div className={Classes.recipients}>
          <h6>
            {t("allMembers")} ({recipients.length})
          </h6>
          {recipients.map((item) => (
            <div
              key={item.member_id}
              className={Classes.recipient}
              onClick={(e) => onClick(e, item.member_id)}
            >
              <div>
                <Avatar name={item.name} className={Classes.avatar} />
              </div>
              <div className={Classes.details}>
                {item.role_id === ADMIN_ID ? (
                  <AccountName account={item} withName />
                ) : (
                  item.name
                )}
                {!!withSendButton && (
                  <button
                    className={Classes.send}
                    onClick={onSend.bind(null, item)}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>
          ))}
          {!recipients.length && (
            <div className={Classes.empty}>{t("nothingFound")}</div>
          )}
        </div>
      </div>
    </PortalNode>
  );
};

export default Recipient;
