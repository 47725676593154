export const messageFormInitial = {
  content: '',
  recipient: null,
  isSystemMessage: false,
};

export const forwardMessageFormInitial = {
  content: '',
  message_id: '',
  receiver_id: null,
  receiver: '',
};

export const FETCH_TIMEOUT = 500;
