import { useEffect } from "react";
import { getContents } from "services/apiService";
import { setParsedNavigation } from "store/actionCreators/navigation";
import { useDispatch } from "react-redux";
import navigation from "config/navigation.js";

const useNavigation = () => {
  const dispatch = useDispatch();

  const fetchContents = async () => {
    const result = {};
    for (let section in navigation) {
      for await (let option of navigation[section].options) {
        const contents = await getContents(option.content, dispatch);
        contents?.forEach(
          (content) =>
            (result[content.content_id] = {
              apply: option.apply,
              next: option.next,

              // TODO: change this
              short_name_en: content.short_name_en,
              short_name_es: content.short_name_es,
            })
        );
      }
    }
    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      const navigationHash = await fetchContents();
      dispatch(setParsedNavigation(navigationHash));
    };

    fetchData();
  }, [dispatch]);
};

export default useNavigation;
