import { all, call } from "redux-saga/effects";
import {
  watchLogin,
  watchLogout,
  watchCheckAuthentication,
} from "./authentication";
import { watchResetPassword, watchUpdateAccount } from "./account";
import { watchCustomers } from "./customers";
import { watchLoginToFetchMessages, fetchMessagesByInterval } from "./messages";

export default function* rootSaga() {
  yield all([
    call(watchLogin),
    call(watchLogout),
    call(watchCheckAuthentication),
    call(watchUpdateAccount),
    call(watchResetPassword),
    call(watchCustomers),
    call(watchLoginToFetchMessages),
    call(fetchMessagesByInterval),
  ]);
}
