export const WOODY_SHRUBS_TYPE_ID = 1;
export const HERBACEOUS_TYPE_ID = 2;
export const VINES_TYPE_ID = 4;

const navigation = {
  category: {
    componentType: "home",
    title: "chooseCategory",
    options: [
      {
        // Woody Shrubs and Small Trees
        content: {
          content_id: WOODY_SHRUBS_TYPE_ID,
        },
        apply: {
          plant_type_id: "$id",
        },
        next: "branch_type",
      },
      {
        // Herbaceous Plants
        content: {
          content_ids: [HERBACEOUS_TYPE_ID],
        },
        apply: {
          plant_type_id: "$id",
        },
        next: "flower_color",
      },
      {
        // Vines
        content: {
          content_ids: [VINES_TYPE_ID],
        },
        apply: {
          // "is_invasive": 1,
          plant_type_id: "$id",
        },
        next: null,
      },
    ],
  },
  branch_type: {
    title: "chooseBranchType",
    options: [
      {
        content: {
          content_ids: [10, 11],
        },
        apply: {
          branch_type_id: "$id",
        },
        next: "leaf_type",
      },
      {
        content: {
          content_id: 16,
        },
        apply: {
          branch_type_id: "$id",
        },
        next: "needle_type",
      },
    ],
  },
  leaf_type: {
    title: "chooseLeafType",
    options: [
      {
        content: {
          type: "leaf_type",
        },
        apply: {
          leaf_type_id: "$id",
        },
        next: null,
      },
    ],
  },
  needle_type: {
    title: "chooseNeedleType",
    options: [
      {
        content: {
          type: "needle_type",
        },
        apply: {
          needle_type_id: "$id",
        },
        next: null,
      },
    ],
  },
  flower_color: {
    componentType: "colors",
    title: "chooseFlowerColor",
    options: [
      {
        tab: "colors",
        content: {
          type: "flower_color",
          display_color: "1",
        },
        apply: {
          flower_color_id: "$id",
        },
        next: null,
      },
      {
        tab: "plant-families",
        content: {
          type: "flower_color",
          display_color: "0",
        },
        apply: {
          flower_color_id: "$id",
        },
        next: null,
      },
    ],
  },

  // These are not used anymore
  flower_vein_type: {
    title: "chooseFlowerVeinType",
    options: [
      {
        content: {
          content_id: 12,
        },
        apply: {
          flower_type_id: "$id",
        },
        next: null,
      },
      {
        content: {
          content_id: 13,
        },
        apply: null,
        next: "flower_symmetry_type",
      },
    ],
  },
  flower_symmetry_type: {
    title: "chooseFlowerSymmetry",
    options: [
      {
        content: {
          content_ids: [14, 15],
        },
        apply: {
          flower_type_id: "$id",
        },
        next: null,
      },
    ],
  },
  invasives: {
    title: "chooseHerbaceousOrVine",
    options: [
      {
        content: {
          content_id: 20,
        },
        apply: {
          plant_type_id: "$id",
        },
        next: "branch_type",
      },
      {
        content: {
          content_id: 21,
        },
        apply: {
          plant_type_id: "$id",
        },
        next: "flower_vein_type",
      },
      {
        content: {
          content_id: 22,
        },
        apply: {
          plant_type_id: "$id",
          grouping_types: ["SINGLE", "SPECIES"],
        },
        next: null,
      },
    ],
  },
};

export default navigation;
