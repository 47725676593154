import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getOfflineAppState, getOfflineStatus } from "store/selectors";

import HamburgerMenu from "components/common/HamburgerMenu";

const NetworkStatus = () => {
  const offline = useSelector(getOfflineStatus);
  const offlineReady = useSelector(getOfflineAppState);
  const { t } = useTranslation();

  return (
    <HamburgerMenu.Item>
      <div className="text-nowrap">
        {!!offline && <div className="text-danger">Offline</div>}
        {!!offlineReady && (
          <div className="small text-right text-info">
            {t("infoMessages.offlineModeAvailable")}
          </div>
        )}
      </div>
    </HamburgerMenu.Item>
  );
};

export default NetworkStatus;
