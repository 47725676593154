import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Classes from "./Password.module.scss";

const Password = ({ change, password, placeholder = "Password", name = "password" }) => {
  const [hidden, setHidden] = useState(true);

  const toggleShow = () => setHidden(!hidden);

  return (
    <div className={Classes.password}>
      <input
        type={hidden ? "password" : "text"}
        placeholder={placeholder}
        name={name}
        onChange={change}
        value={password}
      />
      <button className={Classes.toggler} onClick={toggleShow}>
        {hidden ? (
          <FontAwesomeIcon icon={faEyeSlash} color="grey" />
        ) : (
          <FontAwesomeIcon icon={faEye} color="grey" />
        )}
      </button>
    </div>
  );
}

export default Password;
