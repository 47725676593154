import React from 'react';
import ReactDOM from 'react-dom';

import Classes from './PortalNode.module.scss';

const customRootNode = document.getElementById('custom-root');

const PortalNode = ({ children, onClick }) => {

    if (!children) {
        return '';
    }

    return ReactDOM.createPortal((
        <div className={Classes.modalOverlay} onClick={onClick}>
            {children}
        </div>
    ), customRootNode);
};

export default PortalNode;