import React from "react";
import { withRouter } from "react-router-dom";

import logo from "../../../../assets/images/logo.png";
import Classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={Classes.footer}>
      <img src={logo} className={Classes.logo} alt="Grow with trees" />
    </div>
  );
};

export default withRouter(Footer);
