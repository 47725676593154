import C from "../actionTypes";

// Before it was { parsed: {}, currentScreen: null }
const navInitial = {};

export const navigation = (state = navInitial, action) => {
  switch (action.type) {
    case C.CHANGE_SCREEN:
      return { ...state, currentScreen: action.screen };
    case C.SET_PARSED_NAVIGATION:
      return { ...state, parsed: action.value };
    default:
      return state;
  }
};
