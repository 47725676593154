import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cx from "classnames";
import Classes from './Modal.module.scss';

const modalNode = document.getElementById('modal-root');

const Modal = ({ children, onClose }) => {

  useEffect(() => {
    const closeModal = (event) => {
      event.preventDefault();

      if(event.key === "Escape"){
        onClose(event);
      }
    };

    window.addEventListener("keydown", closeModal);

    return () => {
      window.removeEventListener("keydown", closeModal);
    };
  }, []);


  return ReactDOM.createPortal((
    <div className={Classes.modalOverlay} onClick={(e) => e.stopPropagation()}>
      <div className={Classes.modal}>
        <div className={Classes.content}>
          {children}
        </div>
      </div>
    </div>
  ), modalNode);
};

const Header = ({ text, children, onClose, className = '' }) => (
  <div className={cx(Classes.header, className)}>
    {children}
    {!!onClose && (
      <div className={Classes.close}>
        <FontAwesomeIcon icon={faTimes} onClick={onClose} className={Classes.icon} />
      </div>
    )}
    <div>{text}</div>
  </div>
);

Modal.Header = Header;

Modal.Body = ({ children, className }) => (
  <div className={`${Classes.body} ${className || ''}`}>
    {children}
  </div>
);

Modal.Footer = ({ children }) => (
  <div className={Classes.footer}>
    {children}
  </div>
);

export default Modal;
