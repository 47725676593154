import React from "react";
import Classes from "./Customer.module.scss";
import { NavDropdown } from "react-bootstrap";

const Customer = ({ customers, selectCustomer, currentCustomer }) => {
  // Empty customer list
  if (!customers || !customers.length) {
    return (
      <div className={`${Classes.error}`}>
        No customers for your account
      </div>
    );
  }

  // Single customer case
  if (customers.length === 1) {
    return (
      <div className={`${Classes.singleCustomer}`}>
        {currentCustomer?.name}
      </div>
    );
  }

  return (
    <NavDropdown className={Classes.selector} title={currentCustomer?.name} id="nav-dropdown" onSelect={selectCustomer}>
      {customers.map((customer, index) => (
        <NavDropdown.Item className={Classes.customer} eventKey={index} key={index}>
          {customer.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default Customer;
