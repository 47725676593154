import { useQuiz } from "../QuizContext";

import PortalNode from "components/common/PortalNode";
import Leaderboard from "components/Game/Leaderboard";
import CloseButton from "components/common/CloseButton";

import Classes from "./LeaderboardSection.module.scss";

const LeaderboardSection = () => {
  const [{ showLeaderboard, level }, { dispatchLeaderboard }] = useQuiz();

  const onClose = () => dispatchLeaderboard();

  if (showLeaderboard) {
    return (
      <PortalNode>
        <div className={Classes.container}>
          <Leaderboard
            levelInitial={level}
            closeButton={<CloseButton onClose={onClose} className={Classes.close} />}
          />
        </div>
      </PortalNode>
    )
  }

  return '';
}
export default LeaderboardSection;
