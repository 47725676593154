import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { retakeQuiz } from "services/apiService";
import ReactHtmlParser from "react-html-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faRedo, faMedal } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./Summary.module.scss";

const SuccessSummary = ({ quiz_taken_id, passing_score, score, level, content }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleRetake = async () => {
        const params = {
            quiz_taken_id,
            playAgain: true,
        };
        await retakeQuiz(params, dispatch);
        window.location.reload();
    }

    const contentImage = useMemo(() => {
        const image = content?.images?.find(item => item.mime_type.includes('image'));

        if (image?.img_uri_lowRes) {
            return (
                <div className={Classes.customImage} >
                    <img src={image.img_uri_lowRes} alt={image.title} />
                </div>
            );
        }

        return (
            <div className={Classes.imageContainer} >
                <div className={Classes.logo} >
                    <FontAwesomeIcon size="3x" icon={faMedal} />
                </div>
            </div>
        );
    }, [content]);

    const loadNextLevel = () => history.push(`/game/quiz/${level + 1}`);

    return (
        <div className={Classes.summary}>
            {contentImage}
            <div className={cx(Classes.header, Classes.success)} >
                {content?.name || `Level ${level} Completed`}
            </div>
            <div className={Classes.score} >
                Passing Score {score || 0} / {passing_score || 0}
            </div>
            <div className={Classes.description} >
                {content?.description && ReactHtmlParser(content.description)}
            </div>
            <div className={Classes.actions}>
                <button onClick={handleRetake} className={Classes.button} type="submit">
                    <FontAwesomeIcon icon={faRedo} />
                    <span>Play again</span>
                </button>
                {level < 3 && (
                    <button onClick={loadNextLevel} className={Classes.button} type="submit">
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        <span>Next Level</span>
                    </button>
                )}
            </div>
        </div>
    );
}

export default SuccessSummary;
