import React, { useEffect, useState } from "react";
import { getPlantSections, getPlant } from "services/apiService";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomer } from "store/selectors";

import { PlantSection } from "./Section";
import PlantInfo from "./PlantInfo";
import LoadingIndicator from "components/common/LoadingIndicator";

import cx from "classnames";
import Classes from "./Plant.module.scss";

const SECTIONS_TO_EXCLUDE = ["quick_view"];

const Plant = ({ id, className = ''}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const plantId = new URLSearchParams(history.location.search).get("plantId") || id;
  const customer = useSelector(getCurrentCustomer);
  const [sections, setSections] = useState([]);
  const [pending, setPending] = useState(false);
  const [plant, setPlant] = useState();

  useEffect(() => {
    if (!plantId && !id) {
      history.push("/home");
    }
  }, [plantId]);

  useEffect(() => {
    const fetchPlant = async () => {
      const plantResponse = await getPlant(
        { plant_id: Number(plantId) },
        dispatch
      );

      setPlant(plantResponse);
    };
    if (plantId) {
      fetchPlant();
    }
  }, [plantId]);

  useEffect(() => {
    const fetchData = async () => {
      const reqParams = { plant_id: Number(plantId) };
      if (customer) {
        reqParams.customer_id = customer.customer_id;
      }
      const sectionsReponse = await getPlantSections(reqParams, dispatch);
      if (sectionsReponse) {
        setSections(
          sectionsReponse.filter(
            (section) => !SECTIONS_TO_EXCLUDE.includes(section.type)
          )
        );
      }
      setPending(false);
    };

    setPending(true);
    fetchData();
  }, [plantId, customer, dispatch]);

  return (
    <div className={cx(Classes.container, className)}>
      <PlantInfo plant={plant} />
      {pending && <LoadingIndicator />}
      {sections?.map((section) => (
        <PlantSection key={section.section_id} section={section} />
      ))}
      {!pending && null === plant && (
        <div className={Classes.noResult}>404 not found</div>
      )}
      {!pending && !sections.length && null !== plant && (
        <div className={Classes.noResult}>No info for this plant..</div>
      )}
    </div>
  );
};

export default Plant;
