import { useState, useCallback } from "react";
import { callApi } from "store/sagas/callApi";

const capitalize = (str) => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function useUploadFiles() {
  const [files, setFiles] = useState([]);

  const add = useCallback(
    (event) => {
      const fileArray = Array.from(event.target.files);

      if (fileArray.length > 5) {
        return;
      }

      fileArray.map((file) => {
        file.id = Math.random() * Math.pow(10, 16);
        file.title = capitalize(file.name.replace(/\.[^/.]+$/, ""));
      });
      setFiles((files) => [...files, ...fileArray]);
    },
    [setFiles]
  );

  const remove = useCallback(
    (fileId) => {
      setFiles((prVal) => prVal.filter((val) => val.id !== fileId));
    },
    [setFiles]
  );

  const rename = useCallback(
    (fileId, title) => {
      const newFiles = [...files];
      const newFile = newFiles.find((fl) => fl.id === fileId);
      newFile.title = title;
      setFiles(newFiles);
    },
    [files, setFiles]
  );

  const reset = () => {
    setFiles([]);
  };

  const save = async (item, itemId) => {
    if (files.length > 0) {
      let data = new FormData();
      const titles = {};
      data.append("entity", item);
      data.append("fk", itemId);
      // data.append("token", token);
      files.forEach((f) => {
        data.append("upload", f);
        titles[f.name] = f.title;
      });
      data.append("titles", JSON.stringify(titles));

      const response = await callApi("uploadImages", "POST", data);

      return response;
    }
  };

  return {
    files,
    add,
    save,
    remove,
    rename,
    reset,
  };
}

export default useUploadFiles;
