import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getContents } from "services/apiService";
import { getCurrentCustomer } from "store/selectors";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HamburgerMenu from "components/common/HamburgerMenu";
import { getContentByLanguage } from "helpers/util";

const CustomPageRefs = ({ closeSideBar }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const customer = useSelector(getCurrentCustomer);
  const [pages, setPages] = useState([]);

  const fetchStaticPages = async () => {
    const customerIds = [null];
    if (customer?.customer_id) {
      customerIds.push(customer?.customer_id);
    }
    const contentRes = await getContents(
      { type: "static_page" },
      dispatch,
      customerIds
    );
    if (contentRes) {
      setPages(contentRes);
    }
  };

  useEffect(() => fetchStaticPages(), [customer]);

  const onClick = (page, pageName) => {
    closeSideBar();
    history.push(`/page?pageName=${pageName}&pageId=${page.content_id}`);
  };

  return pages.map((page) => {
    const pageName = page && getContentByLanguage(page, "name");

    return (
      <HamburgerMenu.Item
        key={page.content_id}
        onClick={onClick.bind(null, page, pageName)}
      >
        <span>{pageName}</span>
        <HamburgerMenu.Icon>
          <FontAwesomeIcon icon={faInfoCircle} />
        </HamburgerMenu.Icon>
      </HamburgerMenu.Item>
    );
  });
};

export default CustomPageRefs;
