import actionTypes from "../actionTypes";

export function setOfflineStatus(status) {
  return {
    type: actionTypes.SET_NETWORK_OFFLINE_STATUS,
    status,
  };
}

export function setOfflineModeAvailability(available) {
  return {
    type: actionTypes.SET_OFFLINE_APP_STATUS,
    available,
  };
}

export function initializeOfflineData() {
  return {
    type: actionTypes.INITIALIZE_OFFLINE_STATE,
  };
}
