import React from "react";
import PropTypes from "prop-types";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Classes from "./Main.module.scss";

const WithoutBreadcrumb = ({ children, withBackButton }) => (
    <React.Fragment>
      <Header withBackButton={withBackButton} />
      <main className={Classes.pageContent}>{children}</main>
      {/* <Footer /> */}
    </React.Fragment>
);

WithoutBreadcrumb.propTypes = {
  children: PropTypes.node,
};

export default WithoutBreadcrumb;
