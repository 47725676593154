import actionTypes from "../actionTypes";

export function changeScreen(screen) {
  return {
    type: actionTypes.CHANGE_SCREEN,
    screen,
  };
}

export function setParsedNavigation(value) {
  return {
    type: actionTypes.SET_PARSED_NAVIGATION,
    value,
  };
}
