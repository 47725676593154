import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { applySearchParams } from "store/actionCreators/search";
import { getCurrentCustomer, getSearchParams } from "store/selectors";
import { getApplyParams } from "helpers/util";
import { getContentsByPlantCount } from "services/apiService";
import { changeScreen } from "store/actionCreators/navigation";

const useOption = (content, next, apply) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [contentList, setContentList] = useState([]);
  const searchPlants = useSelector(getSearchParams);
  const customer = useSelector(getCurrentCustomer);
  const [pending, setPending] = useState(false);

  const selectContent = (content_id, withScreenChange = true) => {
    if (!!content_id && withScreenChange) {
      const currentUrl = history.location?.pathname || "/";
      history.push({ pathname: `${currentUrl}/${content_id}` });
    }

    // 2. dispatch action to change current screen to "next" prop
    const params = getApplyParams(apply, content_id);

    // 1. dispatch action to add "apply" prop fields to global search
    dispatch(applySearchParams(params));

    if (withScreenChange) {
      return dispatch(changeScreen(next));
    }
  };

  const fetchContents = async () => {

    if (!content) {
      return setContentList([]);
    }

    const params = {
      filter: content,
      customer_id: customer?.customer_id,
      plantsSearchParams: { ...searchPlants, ...apply },
    };

    const contentRes = await getContentsByPlantCount(params, dispatch);
    if (contentRes) {
      setContentList(contentRes);
    }
    setPending(false);
  };

  useEffect(() => {
    setPending(true);
    fetchContents();
  }, [content, apply, dispatch, searchPlants, customer]);

  return {
    selectContent,
    contentList,
    pending,
  };
};

export default useOption;
