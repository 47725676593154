import { apiUrl, apiRoutes } from "config/api";
import { DEFAULT_LANGUAGE } from "config/constants";
import { getAuthenticationToken } from "helpers/localStorage";
import i18next from "i18next";

export const getApplyParams = (apply = {}, value = "") => {
  const params = { ...apply };
  Object.keys(params).find((key) => {
    if (params[key] === "$id") {
      params[key] = value;
      return 0;
    }

    return -1;
  });

  return params;
};

export const removeUnderscores = (text) => String(text).replaceAll("_", " ");

export const toCamelCase = (str) => {
  if (!str.includes("_")) {
    return str;
  }

  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const fetchData = async (url, body) => {
  try {
    return await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${getAuthenticationToken()}`,
        "Application-Name": "GWT-APP",
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(new Error("error"));
      })
      .catch((error) => {
        return Promise.resolve(new Error(error?.message));
      });
  } catch (error) {
    throw new Error(error);
  }
};

export const url = (route) => {
  return `${apiUrl}${apiRoutes[route]}`;
};

export const getHeaders = (token) => ({
  headers: {
    "Content-Type": "application/json",
    "Application-Name": "GWT-APP",
    Authorization: `JWT ${token}`,
  },
});

// TODO: improve
export const getContentByLanguage = (entity, prefix, ...alternatePrefixes) => {
  if (!entity) {
    return "";
  }

  const getContentByPostfix = (language) =>
    entity[`${prefix}_${language}`] ||
    (alternatePrefixes &&
      entity[
        alternatePrefixes.find(
          (_prefix) => entity[_prefix + language] !== null
        ) + language
      ]);

  return (
    getContentByPostfix(i18next.language) ||
    getContentByPostfix(DEFAULT_LANGUAGE) ||
    ""
  );
};
