import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import cx from "classnames";
import Classes from "./PDF.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContentPDF = ({ img_uri: path  }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function onLoadError(error) {
        console.log('error pdf', error);
    }

    const goToPrevPage = () => {
        setPageNumber(state => {
            const nextPage = state - 1;
            if (nextPage < 1) {
                return state;
            }

            return nextPage;
        });
    }

    const goToNextPage = () => {
        setPageNumber(state => {
            const nextPage = state + 1;
            if (numPages < nextPage ) {
                return state;
            }

            return nextPage;
        });
    }

    return (
        <div id={Classes.PDFContainer}>
            <Document className={Classes.PDFDocument} file={path} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError}>
                <Page className={cx(Classes.PDFPage, Classes.PDFPageOne)} pageNumber={pageNumber} renderTextLayer={false} renderInteractiveForms={false} />
                <nav>
                    <button disabled={pageNumber <= 1} className="m-2 px-5" onClick={goToPrevPage}>
                        Prev
                    </button>
                    <button disabled={pageNumber >= numPages} className="m-2 px-5" onClick={goToNextPage}>
                        Next
                    </button>
                </nav>
            </Document>
        </div>
    )
};

export default ContentPDF;