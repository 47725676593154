import actionTypes from "../actionTypes";

export function requestLogin({ email, password, token }) {
  return {
    type: actionTypes.LOGIN_REQUEST,
    value: { email, password, token },
  };
}

export function successLogin(authData) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    authData,
  };
}

export function failLoginReset() {
  return {
    type: actionTypes.LOGIN_FAILURE_RESET,
  };
}

export function failLogin(authData) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    authData,
  };
}

export function checkAuthentication() {
  return {
    type: actionTypes.CHECK_AUTHENTICATION,
  };
}

export function logout() {
  return {
    type: actionTypes.USER_LOGOUT,
  };
}
