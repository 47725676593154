import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import LeaderboardSection from "../LeaderboardSection";
import Classes from "./LevelInfo.module.scss";

const LevelInfo = ({ level, questionsCount = 0, currentQuestionIndex = 1, showLeaderboard}) => (
    <div className={Classes.container}>
        <div className={Classes.header}>
            <span>Level {level}</span>
            <button onClick={showLeaderboard} className={Classes.leaderboard}>
                <FontAwesomeIcon icon={faTrophy} />
            </button>
        </div>
        {(currentQuestionIndex && questionsCount) ? (
            <div>
                Question {currentQuestionIndex} / {questionsCount}
            </div>
        ) : ''}
        <LeaderboardSection />
    </div>
);

export default LevelInfo;