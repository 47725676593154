import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

import Classes from "./Summary.module.scss";

const Footer = ({ lastQuestionId = {}, loadQuestion }) => {
    return (
        <footer className={Classes.footer}>
            <button onClick={loadQuestion.bind(null, lastQuestionId)} className={Classes.button} type="submit">
                <FontAwesomeIcon icon={faArrowCircleLeft} />
                <span className="mx-2">Back</span>
            </button>
        </footer>
    );
  };
  
  export default Footer;