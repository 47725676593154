import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomer } from "store/selectors";
import { getPlantGrowthZoneContent } from "services/apiService";

import Selector from "components/common/Selector";

import Classes from "./ZoneGrowthSelector.module.scss";
import { DEFAULT_LANGUAGE } from "config/constants";

const getOption = (language = "en", data) => {
  const value = data[`short_name_${language}`];
  const { short_name_en, short_name_es } = data;
  const className = `${Classes[short_name_en]} ${
    language !== "en" ? Classes[short_name_es] : ""
  }`;

  const label = (
    <div className={Classes.zone_growth_option}>
      <div className={`${Classes.optionIcon} ${className}`} />
      <div>{String(value).replace("_", " ")}</div>
    </div>
  );

  return { value, label };
};

const ZoneGrowthSelector = ({ setValue, value, className = "" }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector(getCurrentCustomer);
  const [content, setContent] = useState([]);

  const fetchGrowthZones = async (customer_id) => {
    const response = await getPlantGrowthZoneContent({ customer_id }, dispatch);
    setContent(response);
  };

  useEffect(() => {
    if (customer?.customer_id) {
      fetchGrowthZones(customer.customer_id);
    }

    setContent([]);
  }, [customer?.customer_id]);

  const handleSelectChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setValue("zone_growth", selectedOption.value);
  };

  const OPTIONS = useMemo(() => {
    const initial = { default: { value: "", label: t("growthZone") } };
    return content.reduce((acc, zone) => {
      const langCode = zone[`short_name_${i18n.language}`]
        ? i18n.language
        : DEFAULT_LANGUAGE;
      acc[zone[`short_name_${DEFAULT_LANGUAGE}`]] = getOption(langCode, zone);

      if (langCode !== DEFAULT_LANGUAGE) {
        acc[zone[`short_name_${DEFAULT_LANGUAGE}`]]["value"] =
          zone[`name_${DEFAULT_LANGUAGE}`];
      }

      return acc;
    }, initial);
  }, [content, t]);

  const selectedGrowthZone = useMemo(
    () => OPTIONS[value] ?? OPTIONS.default,
    [OPTIONS, value, i18n.language]
  );

  return (
    <Form.Group
      controlId="zone_growth"
      className="col-sm-12 col-md-6 text-left"
    >
      <Selector
        className={className}
        value={selectedGrowthZone}
        onChange={handleSelectChange}
        options={Object.values(OPTIONS)}
      />
    </Form.Group>
  );
};

export default ZoneGrowthSelector;
