import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getAccount,
  getCustomersList,
  getCurrentCustomer,
  getSideBarMenuState,
  getOfflineStatus,
} from "store/selectors";
import { setCurrentCustomer } from "store/actionCreators/customers";
import { closeSideBarMenu } from "store/actionCreators/sidebar";

import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Items
import Customer from "./components/Customer";
import AdminPanelRef from "./components/AdminPanelRef";
import GameSideBarItem from "./components/GameSideBarItem";
import HomeRef from "./components/HomeRef";
import HamburgerMenu from "components/common/HamburgerMenu";
import LogoutRef from "./components/LogoutRef";
import CustomPageRefs from "./components/CustomPageRefs";
import NetworkStatus from "components/NetworkStatus";
import LanguageSelector from "./components/LanguageSelector";
import MetricsSelector from "./components/MetricsSelector";

import Classes from "./Sidebar.module.scss";

const Sidebar = () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const sideBarMenuState = useSelector(getSideBarMenuState);
  const customers = useSelector(getCustomersList);
  const currentCustomer = useSelector(getCurrentCustomer);
  const offline = useSelector(getOfflineStatus);
  const { t } = useTranslation();

  const selectCustomer = useCallback(
    (index) => {
      dispatch(setCurrentCustomer(customers[index] || ""));
    },
    [customers]
  );

  const closeSideBar = useCallback(() => {
    if (sideBarMenuState) {
      dispatch(closeSideBarMenu());
    }
  }, [dispatch, sideBarMenuState]);

  if (!sideBarMenuState) {
    return "";
  }

  return (
    <HamburgerMenu onClose={closeSideBar}>
      <HamburgerMenu.Header withCloseButton onClose={closeSideBar}>
        <div className={Classes.header}>
          <FontAwesomeIcon
            size="3x"
            icon={faUserCircle}
            className={Classes.avatar}
          />
          <div>
            <span>{t("hi") + " " + account?.name}</span>
            <Customer {...{ customers, selectCustomer, currentCustomer }} />
          </div>
        </div>
      </HamburgerMenu.Header>
      <LanguageSelector />
      <MetricsSelector />
      <HamburgerMenu.Content>
        <NetworkStatus />
        <HomeRef closeSideBar={closeSideBar} />
        <AdminPanelRef
          offline={offline}
          account={account}
          closeSideBar={closeSideBar}
        />
        {!!currentCustomer?.game_enabled && (
          <GameSideBarItem offline={offline} closeSideBar={closeSideBar} />
        )}
        <CustomPageRefs closeSideBar={closeSideBar} />
        <LogoutRef offline={offline} />
      </HamburgerMenu.Content>
    </HamburgerMenu>
  );
};

export default Sidebar;
