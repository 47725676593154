import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HamburgerMenu from "components/common/HamburgerMenu";

const HomeRef = ({ closeSideBar }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => {
    closeSideBar();
    history.push("/");
  };

  return (
    <HamburgerMenu.Item onClick={onClick}>
      <span>{t("home")}</span>
      <HamburgerMenu.Icon>
        <FontAwesomeIcon icon={faHouseUser} />
      </HamburgerMenu.Icon>
    </HamburgerMenu.Item>
  );
};

export default HomeRef;
