import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toggleSideBarMenu } from "store/actionCreators/sidebar";
import { getOfflineStatus, getUnreadMessagesCount } from "store/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faList, faSearch } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./UserMenu.module.scss";

const UserMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const offline = useSelector(getOfflineStatus);
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);

  const onOpenMenu = useCallback((event) => {
    event.stopPropagation();
    dispatch(toggleSideBarMenu());
  }, []);

  const onOpenSearch = useCallback((event) => {
    event.stopPropagation();
    history.push('/search');
  }, []);

  const onOpenMessages = useCallback((event) => {
    if (offline) {
      return '';
    }

    event.stopPropagation();
    history.push('/messages');
  }, []);

  return (
    <div className={Classes.container}>
      <div className={Classes.headerItem} onClick={onOpenSearch} >
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <div className={cx(Classes.headerItem, offline ? Classes.disabled : '')} onClick={onOpenMessages} >
        {unreadMessagesCount > 0 && (
          <span className={Classes.badge}>{unreadMessagesCount}</span>
        )}
        <FontAwesomeIcon icon={faCommentAlt} />
      </div>
      <div className={Classes.headerItem} onClick={onOpenMenu} >
        <FontAwesomeIcon icon={faList} />
      </div>
    </div>
  );
};

export default UserMenu;
