import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";
import Selector from "components/common/Selector";

const GroupingTypeSelector = ({ setValue, value }) => {
  const { t } = useTranslation();

  const OPTIONS = useMemo(
    () => ({
      default: { label: t("groupingType"), value: "", key: "default" },
      single: {
        label: t("single"),
        value: "SINGLE",
        key: "single",
      },
      species: {
        label: t("species"),
        value: "SPECIES",
        key: "species",
      },
    }),
    [t]
  );

  const handleSelectChange = (selectedOption) => {
    setValue("grouping_type", selectedOption.value);
  };

  const selected = useMemo(
    () => (value ? OPTIONS[value.toLowerCase()] : OPTIONS.default),
    [value, OPTIONS]
  );

  return (
    <Form.Group
      controlId="grouping_type"
      className="col-sm-12 col-md-6 text-left"
    >
      <Selector
        value={selected}
        onChange={handleSelectChange}
        options={Object.values(OPTIONS)}
      />
    </Form.Group>
  );
};

export default GroupingTypeSelector;
