import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Classes from "./MetricsSelector.module.scss";
import Selector from "components/common/Selector";
import { setMetrics } from "store/actionCreators/metrics";
import { getCurrentMetricsKey } from "store/selectors";
import { METRICS } from "config/constants";

const MetricsSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedMetricsKey = useSelector(getCurrentMetricsKey);

  const selectMetrics = useCallback((selected) => {
    dispatch(setMetrics(selected.value || ""));
    localStorage.setItem("selected_metrics", selected.value);
  }, []);

  const options = useMemo(
    () =>
      Object.values(METRICS).reduce((prev, option) => {
        prev[option.name] = {
          label: (
            <div className={Classes.languageOption}>
              <span>{t(option.key)}</span>
            </div>
          ),
          value: option.key,
        };

        return prev;
      }, []),
    [METRICS, t]
  );

  const currentValue = useMemo(
    () => ({
      label: (
        <div className={Classes.metricsOption}>
          <span>
            {t(METRICS[selectedMetricsKey]?.key) || t("selectMeasurement")}
          </span>
        </div>
      ),
      value: selectedMetricsKey,
    }),
    [selectedMetricsKey, t]
  );

  return (
    <div className={Classes.container}>
      <Selector
        value={currentValue}
        onChange={selectMetrics}
        options={Object.values(options)}
      />
    </div>
  );
};

export default MetricsSelector;
