import React from "react";
import { useTranslation } from "react-i18next";

import { ACCOUNT_ROLES } from "config/constants";

import cx from "classnames";
import Classes from "./AccountName.module.scss";

const roles = {
  Admin: { abr: "Admin", className: Classes.admin },
  Member: { abr: "Member", className: Classes.member },
  Manager: { abr: "Manager", className: Classes.manager },
};

const AccountName = ({ account, withName }) => {
  const details = roles[ACCOUNT_ROLES[account.role_id]];
  const { t } = useTranslation();

  return (
    <div className={Classes.name}>
      {withName && <div>{account.name}</div>}
      <div className={cx(Classes.role, details.className)}>
        {t(details.abr.toLowerCase())}
      </div>
    </div>
  );
};

export default AccountName;
