import React, { useState } from "react";

import PreviewImage from "components/common/PreviewImage";

import Classes from "./MessageImages.module.scss";

const MessageImages = ({ images = [] }) => {
  const [currentImage, setCurrentImage] = useState(null);

  const handleImagePreview = (data, event) => {
    event.stopPropagation();
    setCurrentImage(data);
  }
  const handleCloseImagePreview = (event) => {
    event.stopPropagation();
    setCurrentImage(null)
  };

  return (
    <>
      <div className={Classes.imageWrapper}>
        {images.map(imgItem => (
          <img
            key={imgItem.message_image_id}
            onClick={handleImagePreview.bind(null, imgItem)}
            src={imgItem.img_uri_lowRes}
            className={Classes.image}
            alt={imgItem.title}
          />
        ))}
      </div>
      {!!currentImage && <PreviewImage image={currentImage} onClose={handleCloseImagePreview} />}
    </>
  );
};

export default MessageImages;
