import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getContentByLanguage } from "helpers/util";

import { getCurrentCustomer, getSearchParams } from "../../store/selectors";
import { getPlants } from "services/apiService";
import Classes from "./Plants.module.scss";
import { useHistory } from "react-router";
import LoadingIndicator from "components/common/LoadingIndicator";
import ImageItem from "components/common/ImageItem";
import Card from "components/common/Card";

const Plants = ({ showHeader = true }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useSelector(getSearchParams);
  const customer = useSelector(getCurrentCustomer);
  const [plants, setPlantsList] = useState([]);
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const plantsRes = await getPlants(
        params,
        customer?.customer_id,
        dispatch
      );

      if (plantsRes) {
        setPlantsList(plantsRes);
      }
      setPending(false);
    };

    if (!pending) {
      setPlantsList([]);
    }
    if (customer?.customer_id && !pending) {
      setPending(true);
      fetchData();
    }
  }, [params, customer]);

  const handleClick = useCallback(
    (plant) => {
      let urlPathname = history.location.pathname;
      if (urlPathname.includes("home")) {
        urlPathname = urlPathname.replace("home", "plant");
      } else if (urlPathname.includes("/search/result")) {
        urlPathname = urlPathname + "/plant";
      }
      history.push(`${urlPathname}?plantId=${plant.plant_id}`);
    },
    [history]
  );

  return (
    <React.Fragment>
      {showHeader && (
        <div className={Classes.header}>{t("chooseThePlant")}</div>
      )}
      <div className={Classes.container}>
        <div className={Classes.list}>
          {pending && <LoadingIndicator />}
          {plants &&
            plants.map((plant, index) => {
              const { plant_sections = [] } = plant;
              const quickView = plant_sections.find((s) => {
                if (s.type !== "quick_view") {
                  return false;
                }

                if (customer?.customer_id && s.customer_id) {
                  return customer.customer_id === s.customer_id;
                }

                return true;
              });

              const image = quickView?.images?.[0];
              return (
                <div className={Classes.item} key={index}>
                  <Card key={index} onClick={handleClick.bind(null, plant)}>
                    {!!image && (
                      <div className={Classes.optionImageContainer}>
                        <ImageItem
                          image={quickView?.images?.[0]}
                          className={Classes.image}
                        />
                      </div>
                    )}
                    <div className={Classes.title}>
                      {getContentByLanguage(plant, "name")}
                    </div>
                  </Card>
                </div>
              );
            })}
          {!pending && (!plants || !plants.length) && (
            <div className={Classes.noResult}>No result</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Plants;
