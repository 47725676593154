import actionTypes from "../actionTypes";

export const openSideBarMenu = () => ({
  type: actionTypes.OPEN_SIDEBAR,
});

export const closeSideBarMenu = () => ({
  type: actionTypes.CLOSE_SIDEBAR,
});

export const toggleSideBarMenu = () => ({
  type: actionTypes.TOGGLE_SIDEBAR,
})