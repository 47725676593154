import React from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Link } from 'react-router-dom';

import Avatar from "components/common/Avatar";

import cx from "classnames";
import Classes from "./ConversationsItem.module.scss";

const ConversationsItem = (element) => {
  const { title, messages, unreads, partner_id } = element;
  const date = moment(messages[0]?.time_sent);
  const lastMessage = messages[messages.length - 1];

  return (
    <Link to={`/conversation/${partner_id}`}>
      <div className={cx(Classes.container, unreads > 0 ? Classes.unread : "")}>
        <div className={Classes.avatar}>
          <Avatar name={title} />
        </div>
        <div className={cx(Classes.content)}>
          <div>{title}</div>
          <div className={Classes.msg}>{ReactHtmlParser(lastMessage?.content)}</div>
        </div>
        <div className={Classes.date}>
          {date.calendar()}
        </div>
      </div>
    </Link>
  );
};

export default ConversationsItem;
