import React from "react";

import cx from "classnames";
import Classes from "./Avatar.module.scss";

function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < (str || "").length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  const hsl = `${h}, ${s}%, ${l}%`;

  return {
    color: `hsl(${hsl})`,
    backgroundColor: `hsl(${hsl}, 0.3)`,
  };
}

function makeAvatarAbbreviation(text) {
  const characters = text
    .split(" ")
    .map(n => n.trim()[0])
    .join("");

  // generate hsl color value
  const styles = stringToHslColor(characters, 100, 30);

  return { characters, ...styles };
}


const Avatar = ({ name = 'No name', className = ''}) => {
  const { color, backgroundColor } = makeAvatarAbbreviation(name);
  return (
    <div className={cx(Classes.avatar, className)} style={{ color, backgroundColor }}>
      {name.charAt(0).toLowerCase().toUpperCase()}
    </div>
  );
}

export default Avatar;
