import types from "../actionTypes";

const selectedMetrics = localStorage.getItem("selected_metrics");

export const metrics = (state = selectedMetrics, action) => {
  switch (action.type) {
    case types.SET_CURRENT_METRICS:
      return action.metrics;
    default:
      return state;
  }
};
