import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Login from "components/Login";
import ResetPassword from "components/ResetPassword";
import PlantBackground from "components/Layouts/PlantBackground";

const Public = () => (
  <PlantBackground>
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/resetpassword">
        <ResetPassword />
      </Route>
      <Redirect to={`/login`} />
    </Switch>
  </PlantBackground>
);

export default Public;
