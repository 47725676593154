import React from "react";
import Answer from "./Answer";
import Classes from "./Answers.module.scss";

const Answers = ({ question, onTakeAnswer, takenAnswer, swipeHandlers }) => (
  <div className={Classes.container} {...swipeHandlers}>
    {question.answers.map((answer, index) =>
      index < 2 ? (
        <Answer
          key={index}
          index={index}
          answer={answer}
          takeAnswer={onTakeAnswer}
          takenAnswer={takenAnswer}
        />
      ) : ("")
    )}
  </div>
);

export default Answers;
