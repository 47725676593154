import React from "react";

import cx from "classnames";
import Classes from "./OptionImage.module.scss";

const OptionImage = ({ data = [], className = '' }) => {
  const image = data?.find(item => {

    if (item.mime_type) {
      return item.mime_type.includes('image')
    }

    return true;
  });

  if (image && image.img_uri_lowRes) {
    return (
      <img
        src={image.img_uri_lowRes}
        className={cx(Classes.image, className)}
        alt={image.title}
      />
    );
  }

  return '';
};

export default OptionImage;
