import { showFailureNotification } from "store/actionCreators/notification";
import { fetchData, url } from "helpers/util";
import { callFetchApi } from "store/sagas/callApi";
import { sendMessageSuccess } from "store/actionCreators/messages";

import axios from "axios";
import { getAuthenticationToken } from "helpers/localStorage";

const api = axios.create({});
export const methods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const createCallAxios = async (
  endpoint,
  method = methods.GET,
  data,
  opt
) => {
  const options = {
    url: endpoint,
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${getAuthenticationToken()}`,
    },
    data,
    ...opt,
  };
  return api(options);
};

export const createCall = async (endpoint, method, data, opt) => {
  try {
    const res = await createCallAxios(endpoint, method, data, opt);
    return res.data;
  } catch (e) {
    console.log(e);
    throw e.data;
  }
};

export const getContents = async (filter = {}, dispatcher, customerIds) => {
  try {
    const reqParams = {
      filter,
    };
    if (customerIds !== undefined) {
      reqParams.customer_ids = customerIds;
    }

    const response = await createCall(url("getContents"), methods.GET, null, {
      params: reqParams,
    });
    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result?.rows;
  } catch (error) {
    console.log(error);
    dispatcher(showFailureNotification("Can't get data"));
  }
};

export const getContentsByPlantCount = async (
  paramsList,
  dispatcher,
  customerIds
) => {
  try {
    const { plantsSearchParams = {}, ...params } = paramsList;
    const reqParams = {
      ...params,
    };
    if (Object.keys(plantsSearchParams).length) {
      reqParams.plantsSearchParams = plantsSearchParams;
    }

    if (customerIds !== undefined) {
      reqParams.customer_ids = customerIds;
    }
    const response = await createCall(
      url("getContentsByPlantCount"),
      methods.GET,
      null,
      { params: reqParams }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result?.rows;
  } catch (error) {
    dispatcher(showFailureNotification("Can't get data"));
  }
};

export const getPlantGrowthZoneContent = async (params, dispatcher) => {
  try {
    const response = await createCall(
      url("getContentsByPlantCount"),
      methods.GET,
      null,
      { params }
    );
    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result?.rows;
  } catch (error) {
    dispatcher(showFailureNotification("Can't get data"));
  }
};

export const getContent = async (params = {}, dispatcher) => {
  try {
    const { content_id } = params;
    const response = await createCall(
      `${url("getContent")}/${content_id}`,
      methods.GET,
      null,
      { params }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Can't get data"));
  }
};

export const getPlants = async (filter = {}, customer_id, dispatcher) => {
  try {
    const reqParams = {
      filter,
    };
    if (customer_id) {
      reqParams.customer_id = customer_id;
      reqParams.onlyCustomerPlants = true;
    }
    const response = await createCall(url("getPlants"), methods.GET, null, {
      params: reqParams,
    });

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    console.log(error);
    dispatcher(showFailureNotification("Error while getting plants list!"));
  }
};

export const getPlant = async ({ plant_id }, dispatcher) => {
  try {
    const response = await createCall(`${url("getPlant")}/${plant_id}`);

    if (!response.success && dispatcher) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    console.log(error);
    if (dispatcher) {
      dispatcher(showFailureNotification("Error while getting plant info"));
    }
  }
};

export const getPlantSections = async (filter = {}, dispatcher) => {
  try {
    const response = await createCall(
      url("getPlantSections"),
      methods.GET,
      null,
      { params: filter }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result?.plantSections;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting plant info"));
  }
};

export const getQuizByCustomer = async (customer_id, account, dispatcher) => {
  try {
    const reqParams = { customer_id };

    if (account?.member_id) {
      reqParams.member_id = account.member_id;
    }

    const response = await createCall(
      `${url("getQuizByCustomer")}/${customer_id}`,
      methods.GET,
      null,
      { params: reqParams }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const getQuizContents = async (params, dispatcher) => {
  try {
    const response = await createCall(
      url("getQuizContents"),
      methods.GET,
      null,
      { params }
    );
    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    console.log("getQuizContents: ", error);
  }
};

export const getQuizContent = async (params, dispatcher) => {
  try {
    const { quiz_content_id } = params;
    const response = await createCall(
      `${url("getQuizContent")}/${quiz_content_id}`
    );
    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting content!"));
  }
};

export const getQuestionsByLevel = async (params, dispatcher) => {
  try {
    const response = await createCall(
      url("getQuestionsByLevel"),
      methods.GET,
      null,
      { params }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const getQuestion = async (params, dispatcher) => {
  try {
    const { quiz_question_id } = params;
    const response = await createCall(
      `${url("getQuestion")}/${quiz_question_id}`
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const skipQuestion = async (params, dispatcher) => {
  try {
    const response = await createCall(
      url("skipQuestion"),
      methods.POST,
      params
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const getQuiz = async (level, customer_id, dispatcher) => {
  try {
    const response = await createCall(
      `${url("getQuiz")}/${customer_id}/${level}`
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    const message = error.message ? error.message : "Error while getting quiz!";
    dispatcher(showFailureNotification(message));
  }
};

// TODO make this more dynamic and without any code duplication
export const getQuizTakenByLevel = async (params, dispatcher) => {
  try {
    const response = await createCall(
      "getQuizTakenByLevel",
      methods.GET,
      null,
      { params }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const submitQuizAnswer = async (params, dispatcher) => {
  try {
    const response = await createCall("submitQuizAnswer", methods.POST, params);

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const retakeQuiz = async (params, dispatcher) => {
  try {
    const response = await createCall(url("resetQuiz"), methods.POST, params);

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(
      showFailureNotification("Something went wrong, please try again!")
    );
  }
};

export const submitQuiz = async (params, dispatcher) => {
  try {
    const response = await createCall(url("submitQuiz"), methods.POST, params);

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(
      showFailureNotification("Something went wrong, please try again!")
    );
  }
};

export const getLeaderboard = async (params, dispatcher) => {
  try {
    const response = await createCall(
      url("getLeaderboard"),
      methods.GET,
      null,
      { params }
    );

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response?.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while getting quiz!"));
  }
};

export const submitMessage = async (data, dispatcher) => {
  try {
    const params = {
      content: data.message,
      recipients: [{ id: data.receiverId }],
    };

    const response = await createCall(url("sendMessage"), methods.POST, params);

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    if (data.appendToConversation) {
      dispatcher(sendMessageSuccess(data));
    }

    return response.result;
  } catch (error) {
    dispatcher(showFailureNotification("Error while sending message!"));
  }
};

export const getAccounts = async (data, dispatcher) => {
  try {
    const response = await createCall(url("getAccounts"), methods.GET, null, {
      params,
    });

    if (!response.success) {
      dispatcher(showFailureNotification(response.error?.message));
    }

    return response.result?.accounts;
  } catch (error) {
    dispatcher(showFailureNotification("Error while sending message!"));
  }
};

export const readMessageThread = async ({ from, to }) => {
  try {
    const response = await createCall(url("readMessageThread"), methods.POST, {
      from,
      to,
    });
    return response?.result;
  } catch (error) {}
};

export const getConversation = async (member_id, partner_id) => {
  try {
    const response = await createCall(
      url("fetchConversation"),
      methods.GET,
      null,
      {
        params: {
          member_id,
          partner_id,
        },
      }
    );
    return response?.result;
  } catch (error) {}
};

export const forwardMessage = async (params) => {
  try {
    const response = await createCall(
      url("forwardMessage"),
      methods.POST,
      params
    );
    return response?.result;
  } catch (error) {}
};
