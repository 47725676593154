const actionTypes = {
  APPLY_SEARCH_PARAMS: "APPLY_SEARCH_PARAMS",
  RESET_SEARCH_PARAMS: "RESET_SEARCH_PARAMS",
  REMOVE_SEARCH_PARAMS: "REMOVE_SEARCH_PARAMS",
  ASSIGN_SEARCH_PARAMS: "ASSIGN_SEARCH_PARAMS",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_FAILURE_RESET: "LOGIN_FAILURE_RESET",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  USER_LOGOUT: "USER_LOGOUT",
  CHECK_AUTHENTICATION: "CHECK_AUTHENTICATION",
  CHANGE_SCREEN: "CHANGE_SCREEN",
  SET_PARSED_NAVIGATION: "SET_PARSED_NAVIGATION",
  ADD_BREADCRUMB_ITEM: "ADD_BREADCRUMB_ITEM",
  REMOVE_BREADCRUMB_ITEM: "REMOVE_BREADCRUMB_ITEM",
  RESET_BREADCRUMB_LIST: "RESET_BREADCRUMB_LIST",
  ASSIGN_BREADCRUMB_LIST: "ASSIGN_BREADCRUMB_LIST",
  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_FAILURE: "UPDATE_ACCOUNT_FAILURE",
  OPEN_SIDEBAR: "OPEN_SIDEBAR",
  CLOSE_SIDEBAR: "CLOSE_SIDEBAR",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",
  SET_CURRENT_CUSTOMER: "SET_CURRENT_CUSTOMER",
  SET_CURRENT_METRICS: "SET_CURRENT_METRICS",
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  SHOW_NOTIFICATION_SUCCESS: "SHOW_NOTIFICATION_SUCCESS",
  SHOW_NOTIFICATION_FAILURE: "SHOW_NOTIFICATION_FAILURE",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",

  SET_NETWORK_OFFLINE_STATUS: "SET_NETWORK_OFFLINE_STATUS",
  SET_OFFLINE_APP_STATUS: "SET_OFFLINE_APP_STATUS",
  INITIALIZE_OFFLINE_STATE: "INITIALIZE_OFFLINE_STATE",
};

export default actionTypes;
