import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import cx from "classnames";
import Classes from './HamburgerMenu.module.scss';
import CloseButton from 'components/common/CloseButton';

const sideBarMenuNode = document.getElementById('side-bar-menu-root');

const HamburgerMenu = ({ children, onClose }) => {

  useEffect(() => {
    const closeSideBarMenu = (event) => {
      event.preventDefault();

      if(event.key === "Escape" && onClose){
        onClose(event);
      }
    };

    window.addEventListener("keydown", closeSideBarMenu);

    return () => {
      window.removeEventListener("keydown", closeSideBarMenu);
    };
  }, []);


  return ReactDOM.createPortal((
    <Overlay onClose={onClose}>
      <div className={Classes.sideBarMenu} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </Overlay>
  ), sideBarMenuNode);
};

const Overlay = ({ children, onClose }) => (
  <div className={Classes.overlay} onClick={onClose}>
    {children}
  </div>
);

HamburgerMenu.Header = ({ children, withCloseButton, onClose }) => (
  <div className={Classes.header}>
    {children}
    {withCloseButton ? <CloseButton onClose={onClose} /> : ''}
  </div>
);

HamburgerMenu.Content = ({ children, className }) => (
  <div className={`${Classes.content} ${className || ''}`}>
    {children}
  </div>
);

HamburgerMenu.Item = ({ children, className, onClick, disabled }) => (
  <div 
    onClick={onClick}
    className={cx(Classes.item, disabled ? Classes.disabled : "", className || '')}
  >
    {children}
  </div>
);

HamburgerMenu.Icon = ({ children }) => (
  <span className={Classes.icon}>
    {children}
  </span>
);

export default HamburgerMenu;
