import { saveAs } from 'file-saver';

import Modal from "components/common/Modal";

import { faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cx from "classnames";
import Classes from "./PreviewImage.module.scss";

const PreviewImage = ({ image, onClose }) => {
    const onDownload = (event) => {
        event.stopPropagation();

        if (navigator.onLine) {
            saveAs(image.img_uri, image.title);
        }
    }
    
    return (
    <Modal onClose={onClose}>
      <Modal.Header className={Classes.header}>
        <div
            onClick={onDownload}
            className={cx(Classes.download, navigator.onLine ? '' : Classes.disabled)}
        >
          <FontAwesomeIcon icon={faDownload} />
        </div>
        <span className={Classes.close} onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      </Modal.Header>
      <Modal.Body>
          <img
            src={navigator.onLine ? image.img_uri : image.img_uri_lowRes}
            className={Classes.previewImage}
            alt={image.title}
          />
      </Modal.Body>
    </Modal>
  );}

export default PreviewImage;