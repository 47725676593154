import Select from "react-select";

import Classes from "./Selector.module.scss";

const Selector = ({ isSearchable = false, ...props }) => (
  <Select
    {...props}
    classNamePrefix="react-select"
    className={Classes.selector}
    isSearchable={isSearchable}
  />
);

export default Selector;
