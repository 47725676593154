import { useDispatch } from "react-redux";
import { submitQuiz } from "services/apiService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faFileAlt, faRedo } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./Summary.module.scss";

const SubmitLevel = ({ quiz_taken_id, passing_score, score, level, skippedCount, fetchQuizTaken }) => {
    const dispatch = useDispatch();
    const message = skippedCount > 1 ? 
        `There are ${skippedCount} questions skipped`
        : `There is ${skippedCount} question skipped`;

    const handleSubmit = async () => {
        const params = { quiz_taken_id };
        await submitQuiz(params, dispatch);
        fetchQuizTaken();
    }

    return (
        <div className={Classes.summary}>
            <div className={Classes.imageContainer} >
                <div className={Classes.logo} >
                    <FontAwesomeIcon size="3x" icon={faFileAlt} />
                </div>
            </div>
           <div className={cx(Classes.header, Classes.submit)} >
                Level {level}
            </div>
            <div className={Classes.score} >
                Passing Score { score || 0 } / { passing_score || 0}
            </div>
            {skippedCount ? (
                <div className={cx(Classes.description, Classes.submit)} >
                    {message}
                </div>
            ) : ''}
            <div className={Classes.actions}>
                <button onClick={handleSubmit} className={Classes.button} type="submit">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>Submit</span>
                </button>
            </div>
        </div>
    );
}

export default SubmitLevel;
