import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { LANGUAGES } from "config/constants";

import Classes from "./LanguageSelector.module.scss";
import Selector from "components/common/Selector";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(null);

  const options = LANGUAGES.reduce((lang, language) => {
    lang[language.code] = {
      label: (
        <div className={Classes.languageOption}>
          <div className={Classes[`flag-${language.code}`]} />
          <span>{t(language.code)}</span>
        </div>
      ),
      value: language.code,
    };

    return lang;
  }, []);

  const onChangeLanguage = useCallback(
    (lang) => {
      if (lang !== selected) {
        i18n.changeLanguage(lang.value);
        localStorage.setItem("language", lang.value);
        setSelected(lang);
      }
    },
    [i18n, selected]
  );

  return (
    <div className={Classes.container}>
      <Selector
        value={options[i18n.language]}
        onChange={onChangeLanguage}
        options={Object.values(options)}
      />
    </div>
  );
};

export default LanguageSelector;
