
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

import Classes from "./Question.module.scss";

const Locked = () => (
  <Row className={Classes.locked}>
    <Col xs="12" className="text-secondary">
      <h4>Question is locked</h4>
      <FontAwesomeIcon size='3x' icon={faLock} />
    </Col>
  </Row>
);

export default Locked;
