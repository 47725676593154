import types from "../actionTypes";

export const messages = (state = [], action) => {
  switch (action.type) {
    case types.GET_MESSAGES_SUCCESS:
      return action.messages;
    case types.SEND_MESSAGE_SUCCESS:
      const msgIndex = state.findIndex(
        (conv) => conv.partner_id === action.receiverId
      );
      const newState = [...state];

      newState[msgIndex]?.messages.push({
        from: action.senderId,
        to: action.receiverId,
        content: action.message,
        message_id: Math.random(),
        containsImage: action.containsImage,
        is_read: false,
      });

      return newState;
    default:
      return state;
  }
};
