import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getForcedToChangePassword } from "store/selectors";

// Layouts
import ContainerWithSideBar from "components/Layouts/ContainerWithSideBar";
import PlantBackground from "components/Layouts/PlantBackground";
import RouteWithLayout from "./RouteWithLayout";
import { Plain, Main, WithoutBreadcrumb, SearchLayout } from "components/Layouts";

// Components
import Plants from "components/Plants";
import Plant from "components/Plant";
import ChangePassword from "components/ChangePassword";
import Conversation from "components/Messages/Conversation";
import Home from "components/Home";
import StaticPage from "components/StaticPage";
import Search from "components/Search";
import GameRoutes from "components/Game";
import Messages from "components/Messages";

const Public = () => {
  const isForcedToChangePassword = useSelector(getForcedToChangePassword);

  return (
  <>
      {isForcedToChangePassword ? (
        <PlantBackground layoutClassName='noBackground'>
          <Switch>
            <RouteWithLayout component={ChangePassword} exact layout={Plain} path={`/home`} />
            <Redirect to={`/home`} />
          </Switch>
        </PlantBackground>
      ) : (
      <ContainerWithSideBar>
        <Switch>
          <RouteWithLayout component={Home} layout={Main} path={`/home`} />
          <RouteWithLayout component={Plant} layout={Main} path={`/plant`} />
          <RouteWithLayout exact component={Search} layout={SearchLayout} path={`/search`} />
          <RouteWithLayout exact component={Messages} layout={WithoutBreadcrumb} withBackButton path={`/messages`} />
          <RouteWithLayout exact component={Conversation} layout={WithoutBreadcrumb} withBackButton path={`/conversation/:partner_id`} />
          <RouteWithLayout exact component={Plants} layout={SearchLayout} withBackButton path={`/search/result`} />
          <RouteWithLayout exact component={Plant} layout={SearchLayout} withBackButton path={`/search/result/plant`} />
          <RouteWithLayout component={StaticPage} exact layout={WithoutBreadcrumb} path="/page" />
          <RouteWithLayout component={GameRoutes} layout={WithoutBreadcrumb} path="/game" />
          <Redirect to={`/home`} />
        </Switch>
      </ContainerWithSideBar>
    )}
  </>
);}

export default Public;
