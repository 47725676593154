import axios from "axios";
import { apiUrl, apiRoutes } from "config/api";
import { getAuthenticationToken } from "helpers/localStorage";
const url = (route) => `${apiUrl}${apiRoutes[route]}`;

export function callApi(action, method, options, headers, api) {
  const axiosConfig = {
    method,
    headers: {
      Authorization: `JWT ${getAuthenticationToken()}`,
      "Application-Name": "GWT-APP",
      ...headers,
    },
    url: url(action),
  };
  
  if (options) {
    if (method === "GET") axiosConfig.params = options;
    else axiosConfig.data = options;
  }

  return axios(axiosConfig)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function callFetchApi(action, method, params) {
  return fetch(url(action), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${getAuthenticationToken()}`,
    },
    method,
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(Error("error"));
    })
    .catch((error) => {
      return Promise.reject(Error(error.message));
    });
}
