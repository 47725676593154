import { useCallback, useEffect, useState } from "react";
import {
  assignSearchParams,
  resetSearchParams,
} from "store/actionCreators/search";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const initialState = {
  name: "",
  growth_speed: "",
  grouping_type: "",
  zone_growth: "",
};

const useSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const processSearch = (url) => {
      const params = new URLSearchParams(url);
      const newFilter = initialState;
      for (let [key, value] of params) {
        if (value && !isNaN(value)) {
          value = parseInt(value);
        }

        newFilter[key] = value;
      }

      return newFilter;
    };
    setFilter(processSearch(history?.location?.search));
    dispatch(resetSearchParams());
  }, []);

  useEffect(() => {
    const search = Object.keys(filter)
      .map((key) => `${key}=${filter[key]}`)
      .join("&");

    history.replace(`${history.location.pathname}?${search}`);
  }, [filter, history]);

  const onSearch = useCallback(() => {
    const searchParams = {};

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        searchParams[key] = filter[key];
      }
    });

    if (!Object.keys(searchParams).length) {
      setErrors({ emptyParams: "One of the fields is required for search!" });
      return;
    }

    dispatch(assignSearchParams(searchParams));
    history.push({ pathname: "/search/result" });
  }, [filter, history]);

  const onChange = useCallback(
    (e) => {
      const changes = {};
      const { type, id, value } = e.target;

      if ("checkbox" === type) {
        changes[id] = Number(!filter[id]);
      } else if (
        [
          "max_height_feet_from",
          "max_height_feet_to",
          "max_spread_feet_from",
          "max_spread_feet_to",
        ].includes(id)
      ) {
        const newValue = parseInt(value);
        changes[id] = newValue >= 0 ? newValue : "";
      } else {
        changes[id] = value;
      }

      setErrors({});
      setFilter((state) => ({ ...state, ...changes }));
    },
    [filter]
  );

  const setFilterValue = (key, value) => {
    setFilter((state) => ({ ...state, [key]: value }));
  };

  return {
    filter,
    errors,
    setFilterValue,
    search: onSearch,
    change: onChange,
  };
};

export default useSearch;
