import { useQuiz } from "./QuizContext";
import useQuestions from "./Questions/useQuestions";

import QuizQuestions from "./Questions";
import LevelInfo from "./LevelInfo";
import LevelProgress from "./LevelProgress";
import MoreInfoSection from "./MoreInfoSection";

import Classes from "./Quiz.module.scss";

const Quiz = () => {
  const [
    { quiz, quizTaken,  },
    { fetchQuizTaken, dispatchLeaderboard },
  ] = useQuiz();

  const { questionsCount, currentScreen, list, ...props } = useQuestions(quiz, quizTaken, fetchQuizTaken);

  if (!currentScreen) {
    return '';
  }

  return (quiz && quizTaken) ? (
    <div className={Classes.container}>
      {currentScreen !== 'summary' && (
        <LevelInfo
          level={quiz.level}
          questionsCount={questionsCount}
          currentQuestionIndex={list?.[currentScreen]?.index}
          showLeaderboard={dispatchLeaderboard}
        />)}
        {currentScreen !== 'summary' && (<LevelProgress {...quiz} {...quizTaken} />)}
      <QuizQuestions
        {...props}
        fetchQuizTaken={fetchQuizTaken}
        currentScreen={currentScreen}
        quiz={quiz}
        quizTaken={quizTaken}
        data={list?.[currentScreen]}
        lastQuestionId={list.lastQuestionId}
      />
      <MoreInfoSection />
    </div>
  ) : '';
};

export default Quiz;
