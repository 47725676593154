import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      additionalInfo: "Additional info",
      admin: "Admin",
      allMembers: "All members",
      alternate: "Alternate",
      back: "Back",
      birdFood: "Bird food",
      chooseBranchType: "Choose Branch Type",
      chooseCategory: "Choose Category",
      chooseFlowerColor: "Choose Flower Color",
      chooseFlowerSymmetry: "Choose Flower Symmetry",
      chooseFlowerVeinType: "Choose Flower Vein Type",
      chooseHerbaceousOrVine: "Choose herbaceous or vine",
      chooseLeafType: "Choose Leaf Type",
      chooseNeedleType: "Choose Needle Type",
      chooseThePlant: "Choose the plant",
      commonNames: "Common names",
      culturallyImportant: "Culturally important",
      description: "Description",
      disclaimer: "Disclaimer",
      dormantSeasonInfo: "dormant season information",
      email: "email",
      empty: "empty",
      en: "English",
      errors: {
        amount: "Invalid Amount",
        email: "Invalid Email",
        forbiddenSymbols: "Some symbols are forbidden",
        minMax:
          'Field must be between $t(min, {"count": {{min}} }) and $t(max, {"count": {{max}} }) characters',
        numeric: "Field must be numeric",
        offlineMode: "Offline mode",
        passwordsMismatch: "Passwords Mismatch",
        required: "Field is required",
        samePasswords: "New and old passwords are the same",
        selectedPlayer: "Please select a player",
        signInUp: {
          1: "Username does not exist",
          2: "Password is incorrect",
          422: "This username is already taken",
          4: "The password must contain both letters and digits with 6 - 16 characters",
          5: "Please provide a valid email",
          6: "This email is not connected to any account.",
          7: "The {{field}} field is empty",
          8: "The username must be at least 3 symbols",
          9: "Max symbols 16",
          10: "No spaces",
          11: "Please Accept terms and conditions",
        },
      },
      es: "Spanish",
      familyName: "Family name",
      fast: "Fast",
      feetInches: "Feet / inches",
      findPlantByColor: "Find plant by color",
      flowers: "flowers",
      forwarded: "Forwarded",
      forwardMessage: "Forward message",
      fruit: "fruit",
      game: "Game",
      glossary: "Glossary",
      groupingType: "Grouping type",
      growthSpeed: "Growth speed",
      growthZone: "Growth zone",
      habitat: "habitat",
      hazard: "Hazard",
      hi: "Hi",
      history: "History",
      home: "Home",
      host: "Host",
      infoMessages: {
        offlineModeAvailable: "Offline mode available!",
        offlineModeReady: "Offline mode ready to use!",
      },
      invasive: "Invasive",
      latinName: "Latin name",
      leaderboard: "Leaderboard",
      leaderboards: "Leaderboards",
      leaves: "leaves",
      level: "Level",
      lobed_leaves: "Lobed Leaves",
      logIn: "Log in",
      loginToIvmClassroom: "Login to IVM Classroom",
      logout: "Logout",
      mammalFood: "Mammal food",
      manager: "Manager",
      medium: "Medium",
      member: "Member",
      metersCentimeters: "Meters / centimeters",
      message: "Message",
      name: "Name",
      noConversations: "No conversations",
      nothingFound: "Nothing found",
      password: "password",
      passwordForgotten: "Forgot password?",
      pipeZoneBorderZone: "Pipe Zone / Border Zone",
      plantFamilies: "Plant families",
      propagation: "Propagation",
      quickView: "Quick view",
      recipientName: "Recipient name",
      score: "Score",
      searchPlantName: "Search plant name",
      seeOriginal: "See original",
      seeTranslation: "See translation",
      selectMeasurement: "Select measurement",
      showResults: "Show Results",
      single: "SINGLE",
      slow: "SLOW",
      species: "SPECIES",
      subnames: "Subnames",
      success: {
        networkAvailable: "Network available",
      },
      symbolIndex: "Symbol Index",
      treatment: "Treatment",
      warnings: {
        invasive: "This plant is invasive",
        hazard: "This plant is potentially hazardous to human health",
      },
      website: "Website",
    },
  },
  es: {
    translation: {
      additionalInfo: "Información adicional",
      admin: "Administrador",
      allMembers: "Todos los miembros",
      alternate: "Alterna",
      back: "Volver",
      birdFood: "Alimento para pájaros",
      chooseBranchType: "Escoger el tipo de la rama",
      chooseCategory: "Escoger categoría",
      chooseFlowerColor: "Escoger el color de la flor",
      chooseFlowerSymmetry: "Escoger la simetría de la flor",
      chooseFlowerVeinType: "Escoger el tipo de vena de la flor",
      chooseHerbaceousOrVine: "Escoger si es herbácea o enredadera",
      chooseLeafType: "Escoger el tipo de hoja",
      chooseNeedleType: "Escoger el tipo de acícula",
      chooseThePlant: "Escoger la planta",
      commonNames: "Nombres comunes",
      culturallyImportant: "Culturalmente importante",
      description: "Descripción",
      disclaimer: "Descargo de responsabilidad",
      dormantSeasonInfo: "información sobre el periodo de latencia",
      email: "correo electrónico",
      empty: "vacío",
      en: "Inglés",
      errors: {
        amount: "Esta cantidad no es válida",
        email: "Este correo electrónico no es válido",
        forbiddenSymbols: "Algunos símbolos son prohibidos",
        minMax:
          'Este campo debe contener entre $t(min, {"count": {{min}} }) y $t(max, {"count": {{max}} }) caracteres',
        numeric: "Este campo debe ser numérico",
        offlineMode: "Modo sin conexión",
        passwordsMismatch: "Las contraseñas no coinciden",
        required: "Este campo es obligatorio",
        samePasswords: "La antigua y la nueva contraseña son iguales",
        selectedPlayer: "Por favor, seleccionar un jugador",
        signInUp: {
          1: "No existe este nombre de usuario",
          2: "La contraseña es incorrecta",
          422: "Este nombre de usuario ya ha sido asignado",
          4: "La contraseña debe tener letras y números con entre 6-16 caracteres",
          5: "Por favor, proporcionar un correo electrónico válido",
          6: "Este correo electrónico no está vinculado a una cuenta",
          7: "El campo {{field}} está vacío",
          8: "El nombre de usuario debe tener al menos 3 símbolos",
          9: "Un máximo de 16 símbolos",
          10: "No utilizar espacios",
          11: "Por favor, aceptar los Términos y Condiciones",
        },
      },
      es: "Español",
      familyName: "Nombre de la familia",
      fast: "Rápido",
      feetInches: "Pies / pulgadas",
      findPlantByColor: "Encontrar planta por color",
      flowers: "flores",
      forwarded: "Reenviado",
      forwardMessage: "Reenviar mensaje",
      fruit: "frutas",
      game: "Juego",
      glossary: "Glosario",
      groupingType: "Tipo de clasificación",
      growthSpeed: "Velocidad de crecimiento",
      growthZone: "Zona de crecimiento",
      habitat: "hábitat",
      hazard: "Peligro",
      hi: "Hola",
      history: "Historia",
      home: "Inicio",
      host: "Hospedadora",
      infoMessages: {
        offlineModeAvailable: "¡Modo sin conexión disponible!",
        offlineModeReady: "¡Modo sin conexión está listo para ser usado!",
      },
      invasive: "Invasora",
      latinName: "Nombre en latín",
      leaderboard: "Tabla de clasificación",
      leaderboards: "Tablas de clasificación",
      leaves: "hojas",
      level: "Nivel",
      lobed_leaves: "Hojas lobuladas",
      logIn: "Iniciar sesión",
      loginToIvmClassroom: "Acceder a la clase del MIV",
      logout: "Cerrar sesión",
      mammalFood: "Alimento para mamíferos",
      manager: "Gerente",
      medium: "Media",
      member: "Miembro",
      metersCentimeters: "Metros / centimetros",
      message: "Mensaje",
      name: "Nombre",
      noConversations: "No hay conversaciones",
      nothingFound: "No se encontró ningún resultado",
      password: "contraseña",
      passwordForgotten: "¿Olvidaste la contraseña?",
      pipeZoneBorderZone: "Zona de tubería / Zona de frontera",
      plantFamilies: "Familias de plantas",
      propagation: "Propagación",
      quickView: "Vista rápida",
      recipientName: "Nombre del destinario",
      score: "Puntaje",
      searchPlantName: "Buscar nombre de la planta",
      seeOriginal: "Ver original",
      seeTranslation: "Ver traducción",
      selectMeasurement: "Escoger medición",
      showResults: "Mostrar Resultados",
      single: "INDIVIDUAL",
      slow: "LENTO",
      species: "ESPECIES",
      subnames: "Subnombres",
      success: {
        networkAvailable: "Red disponible",
      },
      symbolIndex: "Índice de símbolos",
      treatment: "Cura",
      warnings: {
        invasive: "Esta planta es invasiva",
        hazard:
          "Esta planta es potencialmente peligrosa para la salud de las personas",
      },
      website: "Página web",
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: ".",
});

export default i18next;
