import types from "../actionTypes";

const initial = {
  offline: null,
  available: false,
};

export const offlineMode = (state = initial, action) => {
  switch (action.type) {
    case types.SET_NETWORK_OFFLINE_STATUS:
      return { ...state, offline: action.status };
    case types.SET_OFFLINE_APP_STATUS:
      return { ...state, available: action.available };
    case types.INITIALIZE_OFFLINE_STATE:
    case types.USER_LOGOUT:
      return initial;
    default:
      return state;
  }
};
