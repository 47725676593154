import { useCallback, useEffect, useRef, useState } from "react";
import useOption from "../useOption";
import { useTranslation } from "react-i18next";

// Components
import Plants from "components/Plants";
import ColorOption from "./ColorOption";
import LoadingIndicator from "components/common/LoadingIndicator";

import cx from "classnames";
import Classes from "./ColorsScreen.module.scss";

import { TABS } from "../constants";
import { useSelector } from "react-redux";
import { getCurrentCustomer } from "store/selectors";

const Option = ({
  currentTabKey,
  option,
  currentScreen,
  index,
  updateTabList,
}) => {
  const {
    selectContent: select,
    contentList,
    pending,
  } = useOption(option.content, option.next, option.apply);

  useEffect(() => {
    if (currentTabKey === "plant-families" && !pending) {
      const content_id = contentList?.[0]?.content_id;
      select(content_id, false);
    }
  }, [currentTabKey]);

  useEffect(() => {
    if (contentList?.length > 0 && option.tab) {
      updateTabList(option.tab, true);
    } else {
      updateTabList(option.tab, false);
    }
  }, [contentList, option, updateTabList]);

  if (pending) {
    return <LoadingIndicator />;
  }

  if (option.tab === currentTabKey) {
    if (currentTabKey === "plant-families") {
      return <Plants showHeader={false} />;
    }

    return (
      <ColorOption
        index={index}
        option={option}
        contentList={contentList}
        select={select}
        currentScreen={currentScreen}
      />
    );
  }

  return "";
};

const ColorsScreen = ({ currentScreen, options, type }) => {
  const [tabKey, setTabKey] = useState("colors");
  const [tabs, setTabs] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const list = Object.keys(tabs).sort();
    if (list.length > 0) {
      setTabKey(list[0]);
    }
  }, [tabs]);

  const updateTabList = useCallback(
    (key, attach) => {
      if (!tabs[key] && attach) {
        setTabs({ ...tabs, [key]: true });
      } else if (tabs[key] && !attach) {
        const newList = { ...tabs };
        delete newList[key];

        setTabs(newList);
      }
    },
    [setTabs, tabs]
  );

  if (type !== "colors") {
    return "";
  }

  const onTabChange = (event) => {
    const { id } = event.target;
    setTabKey(id);
  };

  return (
    <div className={Classes.container}>
      <div className={Classes.tabs}>
        {Object.keys(tabs)
          .sort()
          .map((item) => (
            <div
              id={TABS[item].key}
              key={TABS[item].key}
              onClick={onTabChange}
              className={cx(
                Classes.tab,
                tabKey === TABS[item].key ? Classes.active : ""
              )}
            >
              {t(TABS[item].title)}
            </div>
          ))}
      </div>
      <div className={Classes.content}>
        {options?.map((option, index) => (
          <Option
            updateTabList={updateTabList}
            currentTabKey={tabKey}
            key={index}
            index={index}
            option={option}
            currentScreen={currentScreen}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorsScreen;
