import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Notification from "components/Notification";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

const browserHistory = createBrowserHistory();

const App = () => {
  return (
    <Router history={browserHistory}>
      <div className="App">
        <Routes />
      </div>
      <Notification />
    </Router>
  );
};
export default App;
