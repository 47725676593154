import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";
import Selector from "components/common/Selector";

const GrowthSpeedSelector = ({ setValue, value }) => {
  const { t } = useTranslation();

  const OPTIONS = useMemo(
    () => ({
      default: { label: t("growthSpeed"), value: "" },
      slow: { label: t("slow").toUpperCase(), value: "SLOW" },
      medium: { label: t("medium").toUpperCase(), value: "MEDIUM" },
      fast: { label: t("fast").toUpperCase(), value: "FAST" },
    }),
    [t]
  );

  const selected = useMemo(
    () => (value ? OPTIONS[value.toLowerCase()] : OPTIONS.default),
    [value, OPTIONS]
  );

  const handleSelectChange = (selectedOption) => {
    setValue("growth_speed", selectedOption.value);
  };

  return (
    <Form.Group
      controlId="growth_speed"
      className="col-sm-12 col-md-6 text-left"
    >
      <Selector
        value={selected}
        onChange={handleSelectChange}
        options={Object.values(OPTIONS)}
      />
    </Form.Group>
  );
};

export default GrowthSpeedSelector;
