import types from "../actionTypes";

export const addBreadcrumb = (item) => ({
  type: types.ADD_BREADCRUMB_ITEM,
  item,
});

export const removeBreadcrumb = (index) => ({
  type: types.REMOVE_BREADCRUMB_ITEM,
  index,
});

export const resetBreadcrumb = () => ({
  type: types.RESET_BREADCRUMB_LIST,
});

export const assignBreadcrumbs = (crumbs = []) => ({
  type: types.ASSIGN_BREADCRUMB_LIST,
  crumbs,
});
