import types from "../actionTypes";

export const applySearchParams = (params) => ({
  type: types.APPLY_SEARCH_PARAMS,
  params,
});

export const resetSearchParams = () => ({
  type: types.RESET_SEARCH_PARAMS,
});

export const removeSearchParams = ( paramsList ) => ({
  type: types.REMOVE_SEARCH_PARAMS,
  paramsList,
});

export const assignSearchParams = (params) => ({
  type: types.ASSIGN_SEARCH_PARAMS,
  params,
});
