export const SCREEN_TYPES = {
  colors: "colors",
  default: "default",
  home: "home",
};

export const TABS = {
  colors: { title: "findPlantByColor", key: "colors" },
  "plant-families": { title: "plantFamilies", key: "plant-families" },
};
