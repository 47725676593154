import React, { useState } from "react";
import { getContentByLanguage } from "helpers/util";

import cx from "classnames";
import Classes from "./ImageItem.module.scss";
import PreviewImage from "../PreviewImage";

const ImageItem = ({ image, className = "", withImagePreview = false }) => {
  const [preview, setPreview] = useState(false);
  const imageTitle = getContentByLanguage(image, "title");

  const onClick = (event) => {
    if (!withImagePreview) {
      return;
    }

    event.stopPropagation();
    setPreview((current) => !current);
  };

  return (
    <>
      <img
        onClick={onClick}
        src={navigator.onLine ? image.img_uri : image.img_uri_lowRes}
        className={cx(Classes.image, className)}
        alt={imageTitle}
      />
      {!!image.show_title && <div>{imageTitle}</div>}
      {preview && <PreviewImage image={image} onClose={onClick} />}
    </>
  );
};

export default ImageItem;
