import types from "../actionTypes";

export const sidebar = (state = false, action) => {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return true;
    case types.CLOSE_SIDEBAR:
      return false;
    case types.TOGGLE_SIDEBAR:
      return !state;
    default:
      return state;
  }
};
