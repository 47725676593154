import React, { useEffect, useMemo, useState } from "react";
import { getQuizContent } from "services/apiService";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomer } from "store/selectors";
import { getContentByLanguage } from "helpers/util";

import ReactHtmlParser from "react-html-parser";

import LoadingIndicator from "components/common/LoadingIndicator";
import ContentFile from "components/common/ContentFile";

import Classes from "./StaticPage.module.scss";
import ImagesCarousel from "components/common/ImagesCarousel";

// TODO: use Static page from components
const StaticPage = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCurrentCustomer);
  const [content, setPageContent] = useState();
  const [pending, setPending] = useState(false);

  const { contentId } = useParams();

  const fetchData = async (customerId) => {
    setPending(true);
    const response = await getQuizContent(
      {
        quiz_content_id: Number(contentId),
        customer_id: customerId,
      },
      dispatch
    );

    setPageContent(response);
    setPending(false);
  };

  useEffect(() => {
    if (customer?.customer_id && contentId) {
      fetchData(customer?.customer_id);
    }
  }, [contentId, customer, dispatch]);

  const elements = useMemo(() => {
    return content?.images.reduce(
      (acc, item) => {
        if (item.mime_type === "application/pdf") {
          acc.files.push(item);
        } else {
          acc.images.push(item);
        }

        return acc;
      },
      { images: [], files: [] }
    );
  }, [content]);

  if (content === undefined) {
    return "";
  }

  return (
    <div className={Classes.container}>
      <div className={Classes.header}>
        <h3>{decodeURIComponent(getContentByLanguage(content, "name"))}</h3>
      </div>
      {pending && <LoadingIndicator />}
      {!!content && (
        <>
          <div className={Classes.images}>
            {elements.files.map((item) => (
              <ContentFile {...item} key={item.content_image_id} />
            ))}
          </div>

          <div className={Classes.content}>
            {ReactHtmlParser(getContentByLanguage(content, "description"))}
          </div>

          <ImagesCarousel
            images={elements.images}
            className={Classes.image}
            imageId="content_image_id"
          />
        </>
      )}
      {!pending && !content && (
        <div className={Classes.noResult}>No info...</div>
      )}
    </div>
  );
};

export default StaticPage;
