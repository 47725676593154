import types from "../actionTypes";
import configs from "config/config";

export const customers = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CUSTOMERS_REQUEST:
    case types.GET_CUSTOMERS_FAILURE:
      return {};
    case types.GET_CUSTOMERS_SUCCESS:
      const firstCustomer = action.customers?.[0];
      const gwtCustomer =
        action.customers.find(
          (cst) => cst.customer_id === configs.GWT_CUSTOMER_ID
        ) || firstCustomer;
      return {
        ...state,
        list: action.customers,
        selected: action.isAdmin ? gwtCustomer : firstCustomer,
      };
    case types.SET_CURRENT_CUSTOMER:
      return { ...state, selected: action.customer };
    default:
      return state;
  }
};
