import React from "react";

import Classes from "./Email.module.scss";

const Email = ({ change, email }) => (
  <div className={Classes.email}>
    <input
      type="email"
      placeholder="Email address"
      name="email"
      value={email}
      onChange={change}
    />
  </div>
);

export default Email;
