export const getPassedPercentage = (passingScore = 0, currentScore = 0) => {
    const percentage = (currentScore * 100) / passingScore;
    return percentage > 100 ? 100 : Math.round(percentage);
}

export const isCorrectAnswer = (takenAnswer, answer) => answer?.quiz_answer_id === takenAnswer?.correct_answer_id;

export const fillLevels = (data = []) => {
    const count = data.length || 0;
    return [
        ...data,
        ...(count === 0 ? [{ level: 1, locked: true }] : []),
        ...(count <= 1 ? [{ level: 2, locked: true }] : []),
        ...(count <= 2 ? [{ level: 3, locked: true }] : []),
    ];
}