import React from "react";
import PropTypes from "prop-types";

import Header from "../components/Header";

import Classes from "./Main.module.scss";

const SearchLayout = ({ children, withBackButton }) => (
  <React.Fragment>
    <Header withBackButton={withBackButton} />
    <main className={Classes.pageContent}>
      {children}
    </main>
  </React.Fragment>
);

SearchLayout.propTypes = {
  children: PropTypes.node,
};

export default SearchLayout;
