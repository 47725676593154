import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HazardCheckbox = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="is_hazard" className="my-3 py-3 col-6">
      <Form.Check
        type="checkbox"
        label={t("hazard")}
        onChange={onChange}
        checked={Boolean(value)}
      />
    </Form.Group>
  );
};

export default HazardCheckbox;
