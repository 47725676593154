import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAuthenticationToken } from "store/selectors";

import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HamburgerMenu from "components/common/HamburgerMenu";

import config from "config/config";

const ADMIN = 1;
const MANAGER = 3;

const AdminPanelRef = ({ account, offline, closeSideBar }) => {
  const token = useSelector(getAuthenticationToken);
  const { t } = useTranslation();

  const onClick = () => {
    if (offline) {
      return;
    }

    closeSideBar();
    window.location.replace(`${config.adminUrl}?token=${token}`);
  };

  return [ADMIN, MANAGER].includes(account?.role_id) ? (
    <HamburgerMenu.Item onClick={onClick} disabled={offline}>
      <span>{t("admin")}</span>
      <HamburgerMenu.Icon>
        <FontAwesomeIcon icon={faUserShield} />
      </HamburgerMenu.Icon>
    </HamburgerMenu.Item>
  ) : (
    ""
  );
};

export default AdminPanelRef;
