import React from "react";
import ReactHtmlParser from "react-html-parser";

import Card from "components/common/Card";
import { getContentByLanguage } from "helpers/util";

import Classes from "./ColorOption.module.scss";

const ColorOption = ({ contentList, select }) => {

  return contentList?.map((content) => {
    const description = getContentByLanguage(content, "description");

    return (
      <Card
        onClick={select.bind(null, content.content_id)}
        className={Classes.option}
        key={content.content_id}
      >
        <div
          className={Classes.triangle}
          style={{
            backgroundColor: !!content.display_color
              ? content.color
              : "var(--primaryTextColor);",
          }}
        />
        <div className={Classes.content}>
          <div className={Classes.title}>
            {getContentByLanguage(content, "name", "short_name")}
          </div>
          {!!description && (
            <div className={Classes.description}>
              {ReactHtmlParser(description)}
            </div>
          )}
        </div>
      </Card>
    )
  });
};

export default ColorOption;
