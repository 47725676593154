import C from "../actionTypes";

export const authData = (state = {}, action) => {
  switch (action.type) {
    case C.LOGIN_SUCCESS:
      return action.authData;
    case C.LOGIN_FAILURE:
      return { ...action.authData, authenticated: false };
    case C.LOGIN_FAILURE_RESET:
      return { ...state, loginError: '' };
    case C.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.authData,
      };
    case C.USER_LOGOUT:
      return { authenticated: false };
    default:
      return state;
  }
};
