import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getContentByLanguage } from "helpers/util";
import { getCurrentMetricsKey } from "store/selectors";
import {
  HERBACEOUS_TYPE_ID,
  VINES_TYPE_ID,
  WOODY_SHRUBS_TYPE_ID,
} from "config/navigation";
import { METRIC_SYSTEM_KEY } from "config/constants";

import { getContent } from "services/apiService";
import Classes from "./Plant.module.scss";
import { DEFAULT_LANGUAGE } from "config/constants";

const convertToMetric = (feet, inches) => {
  const totalInches = feet * 12 + inches;
  const meters = totalInches / 39.37;
  const centimeters = meters * 100;

  return [Math.floor(meters), Math.round(centimeters) % 100];
};

const renderDetails = (field, plant, metersSelected) => {
  if (
    !plant[`${field}_feet_from`] &&
    !plant[`${field}_inch_from`] &&
    !plant[`${field}_feet_to`] &&
    !plant[`${field}_inch_to`]
  ) {
    return false;
  }

  const [greatMeasureFrom, smallMeasureFrom, greatMeasureTo, smallMeasureTo] =
    metersSelected
      ? [
          ...convertToMetric(
            plant[`${field}_feet_from`],
            plant[`${field}_inch_from`]
          ),
          ...convertToMetric(
            plant[`${field}_feet_to`],
            plant[`${field}_inch_to`]
          ),
        ]
      : [
          plant[`${field}_feet_from`],
          plant[`${field}_inch_from`],
          plant[`${field}_feet_to`],
          plant[`${field}_inch_to`],
        ];

  return (
    <div className={Classes.details}>
      <div className={`${Classes.infoLabel} ${Classes[field]}`}>
        <div className={Classes.textInfo}>
          <div>
            {`${greatMeasureFrom || 0}${metersSelected ? `m` : `'`} `}
            {`${smallMeasureFrom || 0}${metersSelected ? `cm` : `"`}`}
          </div>
          <div>{` - `}</div>
          <div>
            {`${greatMeasureTo || 0}${metersSelected ? `m` : `'`} `}
            {`${smallMeasureTo || 0}${metersSelected ? `cm` : `"`}`}
          </div>
        </div>
      </div>
    </div>
  );
};

const Invasive = ({ t }) => (
  <div className={Classes.invasiveWarn}>
    <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
    <span>{t("warnings.invasive")}</span>
  </div>
);

const Hazard = ({ t }) => (
  <div className={Classes.hazardWarn}>
    <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
    <span>{t("warnings.hazard")}</span>
  </div>
);

const PlantInfo = ({ plant }) => {
  const currentMetricsKey = useSelector(getCurrentMetricsKey);
  const dispatch = useDispatch();
  const [content, setContent] = useState();
  const { t, i18n } = useTranslation();
  const nameFamily = getContentByLanguage(plant, "name_family");
  const nicknames = getContentByLanguage(plant, "nicknames");
  const subnames = getContentByLanguage(plant, "subnames");

  useEffect(() => {
    const fetchContent = async () => {
      const contentResponse = await getContent(
        { [`short_name_${DEFAULT_LANGUAGE}`]: plant.zone_growth },
        dispatch
      );

      setContent(contentResponse);
    };
    if (plant) {
      fetchContent();
    }
  }, [plant]);

  if (!plant) return false;

  return (
    <>
      <div className={Classes.header}>
        <h3>{getContentByLanguage(plant, "name")}</h3>
        {!!plant.is_invasive && <Invasive t={t} />}
        {!!plant.is_hazard && <Hazard t={t} />}
      </div>
      <div className={Classes.namesList}>
        {nameFamily && (
          <div>
            <label>{t("familyName")}</label>
            <div>{nameFamily}</div>
          </div>
        )}
        {nicknames && (
          <div>
            <label>{t("commonNames")}</label>
            <div>{nicknames}</div>
          </div>
        )}
        {!!plant.name_latin && (
          <div>
            <label>{t("latinName")}</label>
            <div>{plant.name_latin}</div>
          </div>
        )}
        {!!subnames && (
          <div>
            <label>{t("subnames")}</label>
            <div>{subnames}</div>
          </div>
        )}
      </div>
      <div className={Classes.info}>
        {!!content && !!plant.zone_growth && (
          <div className={Classes.details}>
            <div
              className={`${Classes.infoLabel} ${
                Classes[
                  content[`short_name_${i18n.language}`] ||
                    content[`short_name_${DEFAULT_LANGUAGE}`]
                ]
              }`}
            />
          </div>
        )}
        {!!plant.growth_speed && (
          <div className={Classes.details}>
            <div
              className={`${Classes.infoLabel} ${
                Classes[`${plant.growth_speed.toLowerCase()}_growth`]
              }`}
            />
          </div>
        )}
        {!!(plant.plant_type_id == WOODY_SHRUBS_TYPE_ID) && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.woody_shrubs}`} />
          </div>
        )}
        {!!(plant.plant_type_id == HERBACEOUS_TYPE_ID) && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.herbaceous}`} />
          </div>
        )}
        {!!(plant.plant_type_id == VINES_TYPE_ID) && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.vines}`} />
          </div>
        )}
        {renderDetails(
          "max_spread",
          plant,
          currentMetricsKey == METRIC_SYSTEM_KEY
        )}
        {renderDetails(
          "max_height",
          plant,
          currentMetricsKey == METRIC_SYSTEM_KEY
        )}
      </div>
      <div className={Classes.info}>
        {!!plant.is_culturally_important && (
          <div className={Classes.details}>
            <div
              className={`${Classes.infoLabel} ${Classes.culturally_important}`}
            />
          </div>
        )}
        {!!plant.is_hazard && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.hazard}`} />
          </div>
        )}
        {!!plant.is_mammal_food && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.mammalFood}`} />
          </div>
        )}
        {!!plant.is_bird_food && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.birdFood}`} />
          </div>
        )}
        {!!plant.is_host && (
          <div className={Classes.details}>
            <div className={`${Classes.infoLabel} ${Classes.host}`} />
          </div>
        )}
      </div>
    </>
  );
};

export default PlantInfo;
