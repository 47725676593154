import React from "react";

import Classes from "./HomeScreenPrompt.module.scss";
import useAddToHomescreenPrompt from "hooks/useAddToHomescreenPrompt";

const HomeScreenPrompt = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  if (!prompt) {
    return null;
  }

  return (
    <div className={Classes.container}>
      <button className={Classes.buttonAdd} onClick={promptToInstall}>
        Download App
      </button>
    </div>
  );
};

export default HomeScreenPrompt;
