import React from "react";
import ReactHtmlParser from "react-html-parser";

import useOption from "../../useOption";
import { getContentByLanguage } from "helpers/util";

import OptionImage from "../../../common/OptionImage";
import Card from "components/common/Card";

import cx from "classnames";
import Classes from "./FullImageOption.module.scss";
import LoadingIndicator from "components/common/LoadingIndicator";

const FullImageOption = ({ content: option, next, apply }) => {
  const { selectContent, contentList, pending } = useOption(
    option,
    next,
    apply
  );

  if (pending) {
    return <LoadingIndicator />;
  }

  return contentList?.map((content) => (
    <div
      key={content.content_id}
      className={Classes.container}
      onClick={selectContent.bind(null, content.content_id)}
    >
      <Card
        className={cx(Classes.option, content.color ? Classes.colorOption : "")}
        key={content.content_id}
      >
        <OptionImage data={content.images} className={Classes.image} />
        <div className={Classes.content}>
          <div className={Classes.header}>
            {getContentByLanguage(content, "name", "short_name")}
          </div>
          {content.description_en && (
            <div className={Classes.description}>
              {ReactHtmlParser(getContentByLanguage(content, "description"))}
            </div>
          )}
        </div>
      </Card>
    </div>
  ));
};

export default FullImageOption;
