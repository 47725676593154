import ImagesCarousel from "components/common/ImagesCarousel";
import Answers from "./Answers";

import Classes from "./BinaryQuestion.module.scss";
import { getContentByLanguage } from "helpers/util";

const BinaryQuestion = ({ question, takenAnswer, ...props }) => {
  return (
    <div className={Classes.container}>
      {!!question.images?.length && (
        <ImagesCarousel
          images={question.images}
          imageId="question_image_id"
          containerClassName={Classes.images}
          className={Classes.image}
        />
      )}
      <div className={Classes.text}>
        {getContentByLanguage(question, "text")}
      </div>
      <Answers {...props} question={question} takenAnswer={takenAnswer} />
    </div>
  );
};

export default BinaryQuestion;
