import v from "validator";
import i18next from "i18next";

const nameMin = 3;
const nameMax = 16;
const passwordMin = 6;
const passwordMax = 16;

class ValidationService {
  static isRequired(param) {
    if (!param) {
      return i18next.t("errors.required");
    }
    return null;
  }

  static isEmail(param, isRequired) {
    if (isRequired && !param) {
      return i18next.t("errors.required");
    }
    if (!isRequired && !param) {
      return null;
    }
    if (!v.isEmail(String(param))) {
      return i18next.t("errors.email");
    }

    return null;
  }

  static isName(param, isRequired) {
    if (isRequired && !param) {
      return i18next.t("errors.required");
    }
    if (!isRequired && !param) {
      return null;
    }
    if (v.matches(String(param), /[`~!@#$%^&*()=+|{}/[];:'",<.>?]/)) {
      return i18next.t("errors.forbiddenSymbols");
    }
    if (!v.isLength(String(param), { min: nameMin, max: nameMax })) {
      return i18next.t("errors.minMax", { min: nameMin, max: nameMax });
    }

    return null;
  }

  static isPassword(param, isRequired) {
    if (isRequired && !param) {
      return i18next.t("errors.required");
    }
    if (!isRequired && !param) {
      return null;
    }
    if (v.matches(String(param), /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])+$/)) {
      return i18next.t("errors.forbiddenSymbols");
    }
    if (!v.isLength(String(param), { min: passwordMin, max: passwordMax })) {
      return i18next.t("errors.minMax", { min: passwordMin, max: passwordMax });
    }
    return null;
  }

  static isNumeric(param, isRequired) {
    if (isRequired && !param) {
      return i18next.t("errors.required");
    }
    if (!isRequired && !param) {
      return null;
    }
    if (!v.isNumeric(String(param))) {
      return i18next.t("errors.numeric");
    }

    return null;
  }

  static isAmount(param, isRequired) {
    if (isRequired && !param) {
      return i18next.t("errors.required");
    }
    if (!isRequired && !param) {
      return null;
    }
    let amount = String(param).split(".");
    if (
      !v.isFloat(String(param), { min: 0.0, max: 100000.0 }) ||
      (amount[1] && amount[1].length > 2)
    ) {
      return i18next.t("errors.amount");
    }

    return null;
  }
}

export default ValidationService;
