
import Question from "../Question";
import LevelSummary from "../LevelSummary";
import LoadingIndicator from "components/common/LoadingIndicator";

const QuizQuestions = (props) => {
    const {
        currentScreen, quiz, pending, data, quizTaken, lastQuestionId,
        getSkippedCount, fetchQuizTaken, setPending, loadQuestion,
    } = props;

    if (currentScreen !== 'summary' && !data) {
        return '';
    }

    return (
        <>
            {pending && <LoadingIndicator />}
            {currentScreen === 'summary' && (
                <LevelSummary
                    quiz={quiz}
                    quizTaken={quizTaken}
                    fetchQuizTaken={fetchQuizTaken}
                    skippedCount={getSkippedCount()}
                    loadQuestion={loadQuestion}
                    lastQuestionId={lastQuestionId}
                />
            )}
            {currentScreen?.includes('question') && (
                <Question
                    data={data}
                    activeTab={currentScreen}
                    level={quizTaken.level}
                    setQuestionPending={setPending}
                    loadQuestion={loadQuestion}
                />
            )}
        </>
    );
}

export default QuizQuestions;
