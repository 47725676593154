import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCurrentScreen } from "../../store/selectors";
import navigations from "../../config/navigation.js";

import Plants from "components/Plants";
import DefaultScreen from "components/Home/Screen";
import ColorsScreen from "components/Home/ColorsScreen";
import LoadingIndicator from "components/common/LoadingIndicator";

import cx from "classnames";
import Classes from "./Home.module.scss";
import { SCREEN_TYPES } from "./constants";

const Home = () => {
  const currentScreen = useSelector(getCurrentScreen);
  const { t } = useTranslation();
  const type = navigations[currentScreen]
    ? navigations[currentScreen].componentType || "default"
    : null;
  const title =
    type === SCREEN_TYPES.colors ? "" : navigations[currentScreen]?.title;

  return currentScreen === null ? (
    <Plants />
  ) : (
    <div className={Classes.container}>
      {!!title && (
        <div
          className={cx(
            Classes.header,
            Classes[navigations[currentScreen]?.componentType]
          )}
        >
          {t(title)}
        </div>
      )}
      {currentScreen === undefined && <LoadingIndicator />}
      <ColorsScreen
        type={type}
        currentScreen={currentScreen}
        options={navigations[currentScreen]?.options}
      />
      <DefaultScreen
        type={type}
        currentScreen={currentScreen}
        options={navigations[currentScreen]?.options}
      />
    </div>
  );
};

export default Home;
