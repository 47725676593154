import React from "react";
import { useTranslation } from "react-i18next";

import { Form, InputGroup } from "react-bootstrap";

import CloseButton from "components/common/CloseButton";
import Classes from "./PlantNameInput.module.scss";

const PlantNameInput = ({
  value,
  className = "",
  onSearch,
  onChange,
  setValue,
}) => {
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch();
      e.preventDefault();
    }
  };

  const onReset = () => setValue("name", "");
  const { t } = useTranslation();

  return (
    <Form.Group controlId="name" className={`col-12 text-left ${className}`}>
      <InputGroup>
        <Form.Control
          type="text"
          value={value}
          placeholder={t("searchPlantName")}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <InputGroup.Append>
          <InputGroup.Text role="button" className="border-0">
            <CloseButton onClose={onReset} className={Classes.close} />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

export default PlantNameInput;
