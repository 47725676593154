import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import {
  failLoginReset,
  requestLogin,
} from "../../store/actionCreators/authentication";

import Email from "components/common/Email";
import Password from "components/common/Password";

import Classes from "./Login.module.scss";
import { showFailureNotification } from "store/actionCreators/notification";
import { SIGN_IN_UP_ERROR_CODES } from "config/constants";

const SignIn = () => {
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.authData.loginError);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  const login = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  const changeEmail = (e) => {
    const withoutSpaces = e.target.value.replace(/\s/g, ""),
      validator = new RegExp("^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$");

    setEmail(withoutSpaces);
  };

  const changePassword = (e) => {
    const withoutSpaces = e.target.value.replace(/\s/g, ""),
      validator = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$");
    validator.test(withoutSpaces);

    setPassword(e.target.value);
  };

  const loginUser = () => {
    if (password.length > 0 && email.length > 0) {
      dispatch(requestLogin({ email, password }));
    }

    if (!password.length) {
      dispatch(
        showFailureNotification(
          t(`errors.signInUp.${SIGN_IN_UP_ERROR_CODES.emptyField}`, {
            field: t("password"),
          })
        )
      );
    }

    if (!email.length) {
      dispatch(
        showFailureNotification(
          t(`errors.signInUp.${SIGN_IN_UP_ERROR_CODES.emptyField}`, {
            field: t("email"),
          })
        )
      );
    }
  };

  useEffect(() => {
    if (loginError) {
      dispatch(showFailureNotification(loginError));
      dispatch(failLoginReset());
    }
  }, [loginError]);

  return (
    <div className={Classes.container} onKeyUp={login}>
      <div className={Classes.formContainer}>
        <label className={Classes.label}>Sign in</label>
        <div className={Classes.form}>
          <Email change={changeEmail} email={email} />
          <Password change={changePassword} password={password} />
          <div>
            <Link to="resetpassword">
              <button className={Classes.forgot} type="submit">
                {t("passwordForgotten")}
              </button>
            </Link>
          </div>
          <footer>
            <button
              onClick={loginUser}
              className={Classes.submit}
              type="submit"
            >
              {t("logIn")}
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
