import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useQuiz } from "components/Game/Quiz/QuizContext";
import {
  getQuestion,
  skipQuestion,
  submitQuizAnswer,
} from "services/apiService";

import { QUESTION_TYPES } from "components/Game/constants";
import { getCurrentCustomer } from "store/selectors";
import { getContentByLanguage } from "helpers/util";

const useQuestion = ({
  activeTab,
  data = {},
  setQuestionPending,
  loadQuestion,
}) => {
  // TODO: improve this????
  const dispatch = useDispatch();
  const customer = useSelector(getCurrentCustomer);
  const {
    quiz_question_id: questionId,
    quiz_taken_id: quizTakenId,
    nextQuestionId,
  } = data;
  const [question, setQuestion] = useState();
  const [disabled, setDisabled] = useState(false);
  const [takenAnswer, setTakenAnswer] = useState(null);
  const [
    { questionMoreInfo, linkedPlant },
    { dispatchMoreInfo, dispatchFeedback },
  ] = useQuiz();

  const handleSwipeTakeAnswer = (index) => {
    if (question.type === QUESTION_TYPES.multi) {
      return;
    }

    if (question.answers?.length > 0) {
      handleTakeAnswer(question.answers[index]);
    }
  };

  const handleShowMoreInfo = () => {
    const moreInfo = getContentByLanguage(question, "more_info");

    if (
      !(questionMoreInfo || linkedPlant) &&
      (moreInfo || question.linked_plant)
    ) {
      dispatchMoreInfo(moreInfo, question.linked_plant);
    }
  };

  const handleSwipeDown = () => {
    if (!takenAnswer && question.allow_skip) {
      handleSkipQuestion();
    }
  };

  const handlers = useSwipeable({
    onSwiped: (e) => console.log("just swipe", e),
    onSwipedLeft: () => handleSwipeTakeAnswer(0),
    onSwipedRight: () => handleSwipeTakeAnswer(1),
    onSwipedUp: () => console.log("just swipe up"), // handleShowMoreInfo,
    onSwipedDown: () => console.log("just swipe down"), // handleSwipeDown,
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const fetchQuestion = useCallback(async () => {
    const params = {
      quiz_question_id: Number(questionId),
      quiz_taken_id: Number(quizTakenId),
      dispatch,
    };

    const response = await getQuestion(params, dispatch);
    setQuestion(response);
  }, [questionId, quizTakenId, dispatch]);

  const handleSkipQuestion = async () => {
    const params = {
      quiz_question_id: Number(questionId),
      quiz_taken_id: Number(quizTakenId),
      dispatch,
    };

    await skipQuestion(params, dispatch);
    setQuestion((current) => ({ ...current, is_skipped: true }));
    loadQuestion(nextQuestionId);
  };

  const handleTakeAnswer = async (answer) => {
    if (!customer?.customer_id || question.question_answer_id || disabled) {
      return;
    }

    setDisabled(true);
    setQuestionPending(true);

    const params = {
      customer_id: Number(customer?.customer_id),
      quiz_question_id: Number(question.quiz_question_id),
      quiz_answer_id: answer?.quiz_answer_id,
      level: Number(question?.level),
    };

    const response = await submitQuizAnswer(params, dispatch);
    setQuestionPending(false);
    setTakenAnswer(response);

    if (
      !response.is_correct &&
      (question.feedback?.text_en || question.feedback?.images?.length)
    ) {
      dispatchFeedback(true);
    } else {
      loadQuestion(nextQuestionId);
    }
  };

  useEffect(() => {
    if (activeTab == `question-${questionId}`) {
      fetchQuestion();
    }
  }, [activeTab, data.quiz_question_id, questionId, fetchQuestion]);

  useEffect(() => {
    if (question) {
      if (question.takenAnswer) {
        setTakenAnswer(question.takenAnswer);
      }

      setDisabled(question.takenAnswer);
    }
  }, [question]);

  return {
    takeAnswer: handleTakeAnswer,
    skipQuestion: handleSkipQuestion,
    fetchQuestion,
    swipeHandlers: handlers,
    showMoreInfo: handleShowMoreInfo,
    takenAnswer,
    question,
    disabled,
  };
};

export default useQuestion;
