import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import LoadingIndicator from "../../common/LoadingIndicator";

import cx from 'classnames';
import Classes from "./PlantBackground.module.scss";

const PlantBackground = (props) => {
  const { children, layoutClassName } = props;

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <div className={cx(Classes.layout, Classes[layoutClassName] ?? '')}>
        <main className={Classes.pageContent}>
        <Link to="/">
            <div className={Classes.logo} />
          </Link>
          {children}
        </main>
      </div>
    </Suspense>
  );
};

PlantBackground.propTypes = {
  children: PropTypes.node,
};

export default PlantBackground;
