import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import storeFactory from "./store";
import { ToastProvider } from "react-toast-notifications";
import { registerServiceWorker } from "./serviceWorkerRegistration";
import "./config/i18n";

import App from "./App";
import "index.scss";

const store = storeFactory();
window.React = React;
window.store = store;

function setBadge(...args) {
  if (navigator.setAppBadge) {
    navigator.setAppBadge(...args);
  } else if (navigator.setExperimentalAppBadge) {
    navigator.setExperimentalAppBadge(...args);
  } else if (window.ExperimentalBadge) {
    window.ExperimentalBadge.set(...args);
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider autoDismiss autoDismissTimeout={3500}>
      <App store={store} />
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
registerServiceWorker();
setBadge(3);
