import React from "react";
import Carousel from "react-multi-carousel";
import ImageItem from "components/common/ImageItem";

import "react-multi-carousel/lib/styles.css";

import cx from "classnames";
import Classes from "./ImagesCarousel.module.scss";

const carouselResponsiveOptions = {
  // superLargeDesktop: {
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 5,
  // },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ImagesCarousel = ({ images = [], className = '', containerClassName = '', imageId, withTitle }) => (
  <div className={cx(Classes.carouselContainer, containerClassName)}>
    {/* images as carousel */}
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={carouselResponsiveOptions}
      ssr={true} // means to render carousel on server-side.
      infinite={false}
      autoPlay={false}
      keyBoardControl={true}
      className={window.innerWidth > 1024 ? Classes.centeredCarousel : ""}
      // autoPlaySpeed={1000}
      // customTransition="all .5"
      // transitionDuration={500}
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      containerClass="carouselList"
      deviceType={""}
      dotListClass=""
      itemClass="carouselItem"
    >
      {images.map((image, index) => (
        <div className={className} key={index} >
          <ImageItem
            withImagePreview
            key={image[imageId]}
            image={image}
          />
        </div>
      ))}
    </Carousel>
  </div>
)

export default ImagesCarousel;
