import { useState, useEffect } from "react";
import FailedSummary from "./FailedSummary";
import SuccessSummary from "./SuccessSummary";
import LoadingIndicator from "components/common/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { getQuizContents } from "services/apiService";
import { getCurrentCustomer } from "store/selectors";
import Classes from "./Summary.module.scss";
import SubmitLevel from "./SubmitLevel";
import Footer from "./Footer";

const CONTENT_TYPE = {
    done: "SUCCESS_SUMMARY",
    failed: "FAILURE_SUMMARY",
    submitted: "FAILURE_SUMMARY",
}

const LevelSummary = ({ quiz, quizTaken, lastQuestionId, loadQuestion, fetchQuizTaken, skippedCount }) => {
    const dispatch = useDispatch();
    const customer = useSelector(getCurrentCustomer);
    const [content, setContent] = useState(null);

    const fetchLevelSummaryContent = async () => {
        const response = await getQuizContents(
            {
                type: CONTENT_TYPE[quizTaken.status],
                level: quizTaken.level,
                customer_id: customer.customer_id,
            },
            dispatch
        );

        setContent(response?.rows?.[0]);
    }

    useEffect(() => {
        if (fetchQuizTaken) {
            fetchQuizTaken();
            fetchLevelSummaryContent();
        }
    }, [fetchQuizTaken]);

    if (!quizTaken || !quiz) {
        return <div className="text-center">No Summary</div>;
    }

    if (!['done', 'failed', 'submitted'].includes(quizTaken?.status)) {
        return <LoadingIndicator />;
    }

    if (skippedCount > 0 && quizTaken.status === 'failed') {
        return (
            <div className={Classes.container}>
                <SubmitLevel
                    fetchQuizTaken={fetchQuizTaken}
                    skippedCount={skippedCount}
                    level={quiz.level}
                    passing_score={quiz.passing_score}
                    score={quizTaken.score}
                    quiz_taken_id={quizTaken.quiz_taken_id}
                />
            </div>
        ); 
    }

    return (
        <div className={Classes.container}>
            {quizTaken.status === 'done' && (
                <SuccessSummary
                    content={content}
                    level={quiz.level}
                    passing_score={quiz.passing_score}
                    score={quizTaken.score}
                    quiz_taken_id={quizTaken.quiz_taken_id}
                />
            )}
            {['failed', 'submitted'].includes(quizTaken.status) && (
                <FailedSummary
                    content={content}
                    level={quiz.level}
                    passing_score={quiz.passing_score}
                    score={quizTaken.score}
                    quiz_taken_id={quizTaken.quiz_taken_id}
                />
            )}
            <Footer lastQuestionId={lastQuestionId} loadQuestion={loadQuestion} />
        </div>
    );
};

export default LevelSummary;
