import React, { Suspense } from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "../../common/LoadingIndicator";
import Sidebar from "../components/Sidebar";

const ContainerWithSideBar = ({ children }) => (
  <Suspense fallback={<LoadingIndicator />}>
    <Sidebar />
    {children}
  </Suspense>
);

ContainerWithSideBar.propTypes = {
  children: PropTypes.node,
};

export default ContainerWithSideBar;
