import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import HamburgerMenu from "components/common/HamburgerMenu";

const GameSideBarItem = ({ offline, closeSideBar }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => {
    if (offline) {
      return;
    }

    closeSideBar();
    history.push("/game");
  };
  return (
    <HamburgerMenu.Item onClick={onClick} disabled={offline}>
      <span>{t("game")}</span>
      <HamburgerMenu.Icon>
        <FontAwesomeIcon icon={faTrophy} />
      </HamburgerMenu.Icon>
    </HamburgerMenu.Item>
  );
};

export default GameSideBarItem;
