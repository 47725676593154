import { Workbox } from "workbox-window";

export const registerServiceWorker = () => {
  // if ( 'production' !== process.env.NODE_ENV ) {
  //   return;
  // }

  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ("serviceWorker" in navigator) {
    const url = `${process.env.PUBLIC_URL}/serviceWorker.js`;
    const wb = new Workbox(url);
    wb.register()
      .then((response) => {
        console.log("Service worker is registered!");
      })
      .catch((error) => {
        console.log("Error while Service worker registering!", error);
      });
  }
};
