import { take, fork, put, call, cancel, select } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { callFetchApi } from "./callApi";
import { getAccount } from "store/selectors";
import {
  getCustomersFailure,
  getCustomersSuccess,
} from "store/actionCreators/customers";
import { createCall, methods } from "services/apiService";
import { url } from "helpers/util";

// Bad practice
const ADMIN_ROLE_ID = 1;

function* processGetCustomersRequest() {
  try {
    // const token = yield select(getToken);
    const account = yield select(getAccount);
    const params = {
      // token,
      onlyMemberCustomers: true,
      member_id: account.member_id,
    };

    const response = yield call(createCall, url("getCustomers"), methods.GET,null,  params);
    if (!response.success) {
      return yield put(getCustomersFailure({ exception: response.error?.message }));
    }

    yield put(
      getCustomersSuccess({
        customers: response.result.customers,
        isAdmin: account.role_id === ADMIN_ROLE_ID,
      })
    );
  } catch (e) {
    if (!!e && !!e.response) {
      yield put(getCustomersFailure({ exception: e.response }));
    }
  }
}

export function* watchCustomers() {
  let forked = null;
  while (true) {
    yield take([
      actionTypes.LOGIN_SUCCESS,
      actionTypes.GET_CUSTOMERS_REQUEST
    ]);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetCustomersRequest);
  }
}
