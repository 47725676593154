import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BirdFoodCheckbox = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="is_bird_food" className="my-3 py-3 col-6">
      <Form.Check
        label={t("birdFood")}
        type="checkbox"
        onChange={onChange}
        checked={Boolean(value)}
      />
    </Form.Group>
  );
};

export default BirdFoodCheckbox;
