import React from 'react';

import Classes from './Card.module.scss';

const Card = ({ children, className, onClick }) => (
  <div className={`${Classes.card} ${className || ''}`} onClick={onClick} >
    {children}
  </div>
);

export default Card;
