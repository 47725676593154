import types from "../actionTypes";

export const searchParams = (state = {}, action) => {
  switch (action.type) {
    case types.APPLY_SEARCH_PARAMS:
      return { ...state, ...action.params };
    case types.REMOVE_SEARCH_PARAMS:
      const newState = action.paramsList.reduce((acc, element) => {
        delete acc[element];

        return acc;
      }, state);
      return newState;
    case types.ASSIGN_SEARCH_PARAMS:
      return action.params || {};
    case types.RESET_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
};
