import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAccount, getCurrentCustomer } from "store/selectors";
import { getQuizByCustomer } from "services/apiService";

import LevelStatistics from "./LevelStatistics";
import LevelSelector from "./LevelSelector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faTrophy } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./Leaderboard.module.scss";

const Leaderboard = ({ levelInitial, closeButton = "" }) => {
  const dispatch = useDispatch();
  const customer = useSelector(getCurrentCustomer);
  const account = useSelector(getAccount);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState(levelInitial ?? 1);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLevels = async () => {
      if (customer?.customer_id) {
        const response = await getQuizByCustomer(
          customer?.customer_id,
          account,
          dispatch
        );
        setLevels(response);
      }
    };

    fetchLevels();
  }, [account, customer, dispatch]);

  return (
    <div className={Classes.container}>
      <div
        className={cx(Classes.header, closeButton ? Classes.closeButton : "")}
      >
        {closeButton ? (
          closeButton
        ) : (
          <Link to="/game" className={Classes.game}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <span>{t("game")}</span>
          </Link>
        )}
      </div>
      <div className={Classes.title}>
        <h4>
          <FontAwesomeIcon icon={faTrophy} />
          <span>{t("leaderboard")}</span>
        </h4>
      </div>
      <div className={Classes.levels}>
        <LevelSelector list={levels} setValue={setLevel} value={t} />
      </div>
      <div className={Classes.stats}>
        <LevelStatistics level={level} customer={customer} />
      </div>
    </div>
  );
};

export default Leaderboard;
