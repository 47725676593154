import types from "../actionTypes";

const initial = [
  {
    short_name_en: "Home",
    path: "/home",
  },
];
export const breadcrumb = (state = initial, action) => {
  switch (action.type) {
    case types.ADD_BREADCRUMB_ITEM:
      return [...state, action.item];
    case types.REMOVE_BREADCRUMB_ITEM:
      state.splice(action.index + 1);
      return [...state];
    case types.ASSIGN_BREADCRUMB_LIST:
      return [...action?.crumbs];
    case types.RESET_BREADCRUMB_LIST:
      return initial;
    default:
      return state;
  }
};
