import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MenuItems from "./Main";
import { QuizProvider } from "./Quiz/QuizContext";

import Quiz from "components/Game/Quiz";
import Leaderboard from "components/Game/Leaderboard";
import StaticPage from "components/Game/StaticPage";

// Game related routes
const Game = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "var(--gameBackgroundColor)";

        return () => {
            document.body.style.backgroundColor = "var(--primaryBackgroundColor)";
        };
    }, []);

    return (
        <Switch>
            <Route exact path="/game">
                <MenuItems />
            </Route>
            <Route path="/game/leaderboard">
                <Leaderboard />
            </Route>
            <Route path="/game/content/:contentId">
                <StaticPage />
            </Route>
            <Route path="/game/quiz/:level">
                <QuizProvider>
                    <Quiz />
                </QuizProvider>
            </Route>
            <Redirect to="/game" />
        </Switch>
    )
};

export default Game;