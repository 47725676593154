import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserMenu from "./UserMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faHome } from "@fortawesome/free-solid-svg-icons";

import Classes from "./Header.module.scss";

const Header = ({ withBackButton }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClickBack = () => history.goBack();

  return (
    <div className={Classes.header}>
      {withBackButton ? (
        <div onClick={onClickBack} className={Classes.home}>
          <FontAwesomeIcon icon={faAngleLeft} />
          <span>{t("back")}</span>
        </div>
      ) : (
        <Link className={Classes.home} to="/">
          <FontAwesomeIcon size="2x" icon={faHome} />
          <span>{t("home")}</span>
        </Link>
      )}
      <UserMenu />
    </div>
  );
};

export default withRouter(Header);
