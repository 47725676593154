import React from "react";

import { useTranslation } from "react-i18next";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HamburgerMenu from "components/common/HamburgerMenu";
import config from "config/config";

const LogoutRef = ({ offline }) => {
  const { t } = useTranslation();

  const onClick = () => {
    window.location.replace(`${config.adminUrl}/logout`);
  };

  return (
    <HamburgerMenu.Item onClick={onClick} disabled={offline}>
      <span>{t("logout")}</span>
      <HamburgerMenu.Icon>
        <FontAwesomeIcon icon={faSignOutAlt} />
      </HamburgerMenu.Icon>
    </HamburgerMenu.Item>
  );
};

export default LogoutRef;
