import ReactHtmlParser from "react-html-parser";
import { useQuiz } from "../QuizContext";

import ImagesCarousel from "components/common/ImagesCarousel";
import PortalNode from "components/common/PortalNode";

import { getContentByLanguage } from "helpers/util";
import Classes from "./Feedback.module.scss";

const Feedback = ({ feedback }) => {
  const [{ showFeedback }, { dispatchFeedback }] = useQuiz();

  const onClose = () => dispatchFeedback();

  if (!(feedback && showFeedback)) {
    return "";
  }

  return (
    <PortalNode>
      <div className={Classes.container}>
        <div className={Classes.content}>
          {ReactHtmlParser(getContentByLanguage(feedback, "text"))}
          {!!feedback.images?.length && (
            <ImagesCarousel
              images={feedback.images}
              imageId="quiz_question_feedback_image_id"
              containerClassName={Classes.images}
            />
          )}
        </div>
        <div className={Classes.footer}>
          <button onClick={onClose} type="submit">
            OK
          </button>
        </div>
      </div>
    </PortalNode>
  );
};
export default Feedback;
