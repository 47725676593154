import LoadingIndicator from "components/common/LoadingIndicator";
import Locked from "./Locked";
import BinaryQuestion from "./BinaryQuestion";
import MultiQuestion from "./MultiQuestion";

import { QUESTION_TYPES } from "components/Game/constants";

import { getContentByLanguage } from "helpers/util";
import QuestionFooter from "./QuestionFooter";
import Feedback from "../Feedback";
import useQuestion from "./useQuestion";

import Classes from "./Question.module.scss";

const Question = (props) => {
  const {
    question,
    takenAnswer,
    swipeHandlers,
    skipQuestion,
    takeAnswer,
    showMoreInfo,
  } = useQuestion(props);

  if (question === null) {
    return (
      <>
        <Locked />
        <QuestionFooter data={props.data} onNext={props.loadQuestion} />
      </>
    );
  }

  return question ? (
    <div className={Classes.container}>
      {!!(question?.type === QUESTION_TYPES.multi) && (
        <MultiQuestion
          {...props}
          question={question}
          takenAnswer={takenAnswer}
          onSkipQuestion={skipQuestion}
          onTakeAnswer={takeAnswer}
          onShowMoreInfo={showMoreInfo}
        />
      )}
      {!!(question?.type === QUESTION_TYPES.binary) && (
        <BinaryQuestion
          {...props}
          question={question}
          takenAnswer={takenAnswer}
          onSkipQuestion={skipQuestion}
          onTakeAnswer={takeAnswer}
          onShowMoreInfo={showMoreInfo}
          swipeHandlers={swipeHandlers}
        />
      )}
      <QuestionFooter
        data={props.data}
        canSkip={question.allow_skip}
        showMoreInfoButton={
          question.show_more_info &&
          (getContentByLanguage(question, "more_info") || question.linked_plant)
        }
        takenAnswer={takenAnswer}
        skipped={question.is_skipped}
        onNext={props.loadQuestion}
        onSkipQuestion={skipQuestion}
        onShowMoreInfo={showMoreInfo}
      />
      <Feedback feedback={question.feedback} />
      {/* <SwipeHints show={question.more_info} /> */}
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default Question;
