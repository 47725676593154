import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestUpdateAccount } from "../../store/actionCreators/account";
import { getAccount } from "store/selectors";
import { showFailureNotification } from "store/actionCreators/notification";

import Password from "components/common/Password";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import Classes from "./ChangePassword.module.scss";

import config from "config/config";

const pwdHints = {
  pwdLength: "Password length should be 6-16 symbols",
  pwdStructure:
    "Password should include a combination of uppercase letters, lowercase letters, numbers, and symbols.",
};

const validateChangePassword = (form) => {
  const errors = {};
  const validator = new RegExp(
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=_*&]).{6,16})/
  );
  if (!form.password) {
    errors.password = "Empty password";
  } else if (!(form.password.length >= 6 && form.password.length <= 16)) {
    errors.password = pwdHints.pwdLength;
  } else if (!validator.test(form.password)) {
    errors.password = pwdHints.pwdStructure;
  } else if (!form.confirmPassword) {
    errors.password = "Please repeat password";
  } else if (form.password !== form.confirmPassword) {
    errors.password = "Passwords are not matching!";
  }
  if (!Object.keys(errors).length) {
    return null;
  }

  return errors;
};

const ChangePassword = () => {
  const dispatch = useDispatch();

  const user = useSelector(getAccount);

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const onSavePassword = (e) => {
    if (e.key === "Enter") requestUpdate();
  };

  const onChange = (event) => {
    const { name, value } = event.currentTarget;
    setForm((state) => ({ ...state, [name]: value.replace(/\s/g, "") }));
  };

  const requestUpdate = () => {
    const errors = validateChangePassword(form);
    if (errors) {
      dispatch(showFailureNotification(errors.password));
    } else {
      const params = {
        password: form.password,
        needs_reset_password: false,
      };
      dispatch(requestUpdateAccount(user.member_id, params));
    }
  };

  const onLogout = () => {
    window.location.replace(`${config.adminUrl}/logout`);
  };

  return (
    <div className={Classes.container} onKeyUp={onSavePassword}>
      <div className={Classes.formContainer}>
        <p>Hello, {user.name}</p>
        <label className={Classes.label}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span>Set a new Password</span>
        </label>
        <div className={Classes.form}>
          <Password
            change={onChange}
            password={form.password}
            placeholder="New Password"
          />
          <Password
            change={onChange}
            password={form.confirmPassword}
            placeholder="Confirm Password"
            name="confirmPassword"
          />
          <div className={Classes.pwdHints}>
            {Object.values(pwdHints).map((msg) => (
              <div>- {msg}</div>
            ))}
          </div>
          <div className={Classes.footer}>
            <button
              onClick={requestUpdate}
              className={Classes.submit}
              type="submit"
            >
              Confirm
            </button>
            <button onClick={onLogout} className={Classes.submit} type="submit">
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
