import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import Classes from "./ReplyMessage.module.scss";
import { submitMessage } from "services/apiService";

import { Upload, UploadedList } from "components/UploadImage";
import useUploadFiles from "components/UploadImage/useUploadFiles";

const ReplyMessage = ({ receiverId, senderId, addMessage }) => {
  const dispatch = useDispatch();
  const upload = useUploadFiles();

  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const onChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const sendMessage = async () => {
    if (!message?.length && !upload.files.length) {
      return "";
    }

    const params = {
      message,
      receiverId: Number(receiverId),
      senderId: Number(senderId),
      appendToConversation: true,
      containsImage: upload.files?.length > 0,
    };

    setMessage("");
    const response = await submitMessage(params, dispatch);

    if (response?.[0]?.message_id) {
      addMessage({
        from: senderId,
        to: receiverId,
        content: params.message,
        message_id: Math.random(),
        containsImage: params.containsImage,
        is_read: false,
      });
      await upload.save("message", response[0]?.message_id);
      upload.reset();
    }
  };

  return (
    <div className={Classes.container}>
      <div className={Classes.uploadedList}>
        <UploadedList {...upload} />
      </div>
      <div className={Classes.reply}>
        <textarea
          rows="1"
          className={Classes.replyMessage}
          placeholder={t("message") + "..."}
          name="message"
          value={message}
          onChange={onChange}
        />
        <Upload {...upload} className={Classes.upload} />
        <button
          className={Classes.send}
          disabled={!message?.length && !upload.files.length}
          onClick={sendMessage}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ReplyMessage;
