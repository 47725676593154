
import ReactHtmlParser from "react-html-parser";
import { useQuiz } from "../QuizContext";

import CloseButton from "components/common/CloseButton";
import PortalNode from "components/common/PortalNode";
import Plant from "components/Plant";

import Classes from "./MoreInfoSection.module.scss";

const MoreInfoSection = () => {
  const [{ questionMoreInfo, linkedPlant }, { dispatchMoreInfo }] = useQuiz();

  const onClose = () => dispatchMoreInfo();

  if (!questionMoreInfo && !linkedPlant) {
    return '';
  }

  return (
    <PortalNode>
        <div className={Classes.container}>
          <div className={Classes.header}>
            <div className={Classes.text}>More info</div>
            <CloseButton onClose={onClose} className={Classes.close} />
          </div>
          {!!questionMoreInfo && <div className={Classes.content}>
            {ReactHtmlParser(questionMoreInfo)}
          </div>}
          {!!linkedPlant && <Plant id={linkedPlant} className={Classes.plants} />}
      </div>
    </PortalNode>
  )
}
export default MoreInfoSection;
