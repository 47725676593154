import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./QuestionFooter.module.scss";

const SkipButton = ({ skipped, onSkipQuestion }) => (
  !skipped
    ? (
      <button onClick={onSkipQuestion} className={cx(Classes.button, Classes.next)}>
        <span className="mx-2">Skip</span>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    ) : ""
);

const NextButton = ({ onNext, questionId }) => (
  <button onClick={onNext.bind(null, questionId, true)} className={cx(Classes.button, Classes.next)}>
    <span className="mx-2">Next</span>
    <FontAwesomeIcon icon={faAngleDoubleRight} />
  </button>
);

const PreviousButton = ({ onNext, questionId }) => (
  <button onClick={onNext.bind(null, questionId, true)} className={cx(Classes.button, Classes.prev)}>
    <FontAwesomeIcon icon={faAngleDoubleLeft} />
    <span className="mx-2">Previous</span>
  </button>
);

const MoreInfoButton = ({ onShowMoreInfo }) => (
  <button onClick={onShowMoreInfo} className={cx(Classes.button, Classes.more)}>
    <FontAwesomeIcon size="2x" icon={faInfoCircle} />
    <span className="mx-2">Info</span>
  </button>
);

const QuestionFooter = ({ data = {}, skipped, canSkip, showMoreInfoButton, takenAnswer, onNext, onSkipQuestion, onShowMoreInfo }) => {
  const { nextQuestionId, prevQuestionId } = data;
  const showSkipButton = canSkip && !skipped && !takenAnswer;
  const showNextButton = (!showSkipButton && takenAnswer) ? true : false;
  const showPrevButton = !!prevQuestionId;

  return (
    <footer className={Classes.container}>
      <div className={Classes.items}>
        {!!showPrevButton && <PreviousButton onNext={onNext} questionId={prevQuestionId} />}
        {!!showMoreInfoButton && <MoreInfoButton onShowMoreInfo={onShowMoreInfo} /> }
        {!!showSkipButton && <SkipButton skipped={skipped} onSkipQuestion={onSkipQuestion} />}
        {!!showNextButton && <NextButton onNext={onNext} questionId={nextQuestionId} />}
      </div>
    </footer>
  );
};

export default QuestionFooter;
