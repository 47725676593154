import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  showFailureNotification,
  showSuccessNotification,
} from "store/actionCreators/notification";
import {
  setOfflineModeAvailability,
  setOfflineStatus,
} from "store/actionCreators/offlineMode";
import { getOfflineStatus } from "store/selectors";

const useNetworkStatus = () => {
  const dispatch = useDispatch();
  const offline = useSelector(getOfflineStatus);
  const reference = useRef(false);
  const { t } = useTranslation();

  const handleOffline = () => {
    console.log("handleOffline fired!");
    dispatch(setOfflineStatus(true));
  };

  const handleOnline = () => {
    console.log("handleOnline fired!");
    dispatch(setOfflineStatus(false));
  };

  const handleSWNotification = (event) => {
    console.log("handleSWNotification fired!", event.data);
    if (event.data?.offlineModeReady) {
      dispatch(showSuccessNotification("Offline mode is ready for use!"));
    }

    dispatch(setOfflineModeAvailability(event.data?.offlineModeReady));
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      dispatch(setOfflineStatus(true));
    }
  }, [dispatch]);

  useEffect(() => {
    if (reference.current !== offline) {
      if (offline === false) {
        dispatch(showSuccessNotification(t("success.networkAvailable")));
      } else if (offline === true) {
        dispatch(showFailureNotification(t("errors.offlineMode")));
      }
    }

    reference.current = offline;
  }, [offline, dispatch]);

  useEffect(() => {
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = handleSWNotification;
    }

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return false;
};

export default useNetworkStatus;
