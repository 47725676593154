import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import "moment/locale/es";
import { forwardMessage } from "services/apiService";

import ReactHtmlParser from "react-html-parser";

import MessageImages from "../../MessageImages";
import Recipient from "components/Messages/Recipient";
import Actions from "../Actions";

import cx from "classnames";
import Classes from "./Item.module.scss";
import LoadingIndicator from "components/common/LoadingIndicator";

import translate from "translate";
import LanguageDetect from "languagedetect";

import { LANGUAGES, DEFAULT_LANGUAGE } from "config/constants";
import { useCallback } from "react";

const getLanguageObj = (langName) => (obj) =>
  obj.name.toLowerCase() === langName;

const detectLanguageCode = (text) => {
  const lngDetector = new LanguageDetect();

  try {
    const mostPossibleLanguage = lngDetector.detect(text, 1);
    let langName = mostPossibleLanguage[0][0];
    let langObj = LANGUAGES.find(getLanguageObj(langName));

    return langObj.code;
  } catch {
    return DEFAULT_LANGUAGE;
  }
};

const togglerKeywords = {
  SEE_ORIGINAL_KEYWORD: "seeOriginal",
  SEE_TRANSLATION_KEYWORD: "seeTranslation",
};

async function translateTo(lang, text) {
  translate.engine = "google";

  return await translate(text, { from: detectLanguageCode(text), to: lang });
}

const ConversationItem = ({ message, member_id }) => {
  const [forwardingMessage, setForwardingMessage] = useState(null);
  const [showRecipients, setShowRecipients] = useState(false);
  const [pending, setPending] = useState(false);
  const originalText = message.content;
  const originalForwardedText = message.forwardedMessage?.content || "";
  const [text, setText] = useState(originalText);
  const [forwardedText, setForwardedText] = useState(originalForwardedText);
  const { t, i18n } = useTranslation();
  const [translatorToggler, setTranslatorToggler] = useState(
    togglerKeywords.SEE_ORIGINAL_KEYWORD
  );

  useEffect(async () => {
    if (originalText) {
      const result = await translateTo(i18n.language, originalText);
      setText(result);
    } else if (originalForwardedText) {
      const result = await translateTo(i18n.language, originalForwardedText);
      setForwardedText(result);
    }
    setTranslatorToggler(togglerKeywords.SEE_ORIGINAL_KEYWORD);
  }, [i18n.language]);

  const switchToggler = (toggler) =>
    toggler === togglerKeywords.SEE_ORIGINAL_KEYWORD
      ? togglerKeywords.SEE_TRANSLATION_KEYWORD
      : togglerKeywords.SEE_ORIGINAL_KEYWORD;

  const sentTime = useMemo(() => {
    moment.locale(i18n.language);
    return moment(message.time_sent).calendar();
  }, [i18n.language, message.time_sent]);

  const toggleActions = (msg, event) => {
    event.stopPropagation();

    if (pending) {
      return;
    }

    if (event.target.tagName === "A") {
      return;
    }

    setForwardingMessage(msg || null);

    if (showRecipients) {
      setShowRecipients(false);
    }
  };

  const handleForwardMessage = async (receiver, event) => {
    event.stopPropagation();

    if (pending) {
      return;
    }

    if (event.target.tagName === "A") {
      return;
    }

    setPending(true);
    await forwardMessage({
      message_id: forwardingMessage.message_id,
      receiver_id: receiver.member_id,
    });

    setTimeout(() => {
      setShowRecipients(false);
      setForwardingMessage(null);
      setPending(false);
    }, 1000);
  };

  const handleSelect = (event) => {
    event.stopPropagation();

    if (pending) {
      return;
    }

    setShowRecipients(true);
  };

  async function handleTranslation(targetLanguage, txt, toggler) {
    if (originalText) {
      if (toggler === togglerKeywords.SEE_TRANSLATION_KEYWORD) {
        try {
          let result = await translateTo(targetLanguage, txt);
          setText(result);
        } catch {
          setText(txt);
        }
      } else {
        setText(originalText);
      }
    } else if (originalForwardedText) {
      if (toggler === togglerKeywords.SEE_TRANSLATION_KEYWORD) {
        try {
          let result = await translateTo(targetLanguage, txt);
          setForwardedText(result);
        } catch {
          setForwardedText(txt);
        }
      } else {
        setForwardedText(originalForwardedText);
      }
    }
  }

  const toggleTranslation = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleTranslation(
        i18n.language,
        text || originalForwardedText,
        translatorToggler
      );
      setTranslatorToggler(switchToggler);
    },
    [translatorToggler]
  );

  return (
    <div
      key={message.message_id}
      className={cx(
        Classes.message,
        member_id === message.from ? Classes.my : ""
      )}
    >
      {pending && <LoadingIndicator />}
      <div className={cx(Classes.wrapper)}>
        <span
          className={Classes.content}
          onClick={toggleActions.bind(null, message)}
        >
          <span className={Classes.messageText}>
            {ReactHtmlParser(text)}
          </span>
          <MessageImages images={message.images} />
          {!!message.containsImage && <div>Loading...</div>}
          {!!message.forwardedMessage && (
            <div className={Classes.forwarded}>
              <p>{t("forwarded")}: </p>
              {ReactHtmlParser(forwardedText)}
              <MessageImages images={message.forwardedMessage.images} />
            </div>
          )}
          {((!!originalText &&
            detectLanguageCode(originalText) !== i18n.language) ||
            (!!originalForwardedText &&
              detectLanguageCode(originalForwardedText) !== i18n.language)) && (
            <div className={Classes.translate}>
              <a
                href="#"
                className={Classes.translateToggler}
                onClick={toggleTranslation}
              >
                {t(translatorToggler)}
              </a>
            </div>
          )}
        </span>
        <span className={Classes.timeSent}>{sentTime}</span>
      </div>
      {/* ---- Actions screen ---- */}
      {!showRecipients && (
        <Actions
          onClose={toggleActions}
          message={forwardingMessage}
          onClick={handleSelect}
        />
      )}

      {/* ---- Forward message recipient screen ---- */}
      {!!showRecipients && (
        <Recipient
          withSendButton
          onClose={toggleActions}
          forwardMessage={forwardingMessage}
          onSend={handleForwardMessage}
        />
      )}
    </div>
  );
};

export default ConversationItem;
