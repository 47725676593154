import types from "../actionTypes";

const notificationInitial = { message: "", appearance: "success" };
export const notification = (state = notificationInitial, action) => {
  if (action?.exception?.data) {
    return { message: action?.exception?.data?.message  || "Something went wrong!", appearance: "error" };
  }

  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return {
        message: action.message,
        appearance: action.appearance || "success",
      };
    case types.SHOW_NOTIFICATION_SUCCESS:
      return {
        message: action.message,
        appearance: "success",
      };
    case types.SHOW_NOTIFICATION_FAILURE:
      return {
        message: action.message,
        appearance: "error",
      };
    case types.RESET_NOTIFICATION:
      return notificationInitial;
    default:
      return state;
  }
};
