import React from "react";
import ReactHtmlParser from "react-html-parser";

import useOption from "../../useOption";
import { getContentByLanguage } from "helpers/util";

import OptionImage from "../../../common/OptionImage";
import Card from "components/common/Card";
import LoadingIndicator from "components/common/LoadingIndicator";

import cx from "classnames";
import Classes from "./DefaultOption.module.scss";

const DefaultOption = ({ content: option, next, apply }) => {
  const { selectContent, contentList, pending } = useOption(
    option,
    next,
    apply
  );

  if (pending) {
    return <LoadingIndicator />;
  }

  return contentList?.map((content) => (
    <Card
      className={Classes.option}
      onClick={selectContent.bind(null, content.content_id)}
      key={content.content_id}
    >
      <OptionImage data={content.images} />
      <div
        className={cx(
          Classes.header,
          content.description ? "" : Classes.alignCenter
        )}
      >
        {getContentByLanguage(content, "name", "short_name")}
      </div>
      {content.description && (
        <div className={Classes.description}>
          {ReactHtmlParser(content.description)}
        </div>
      )}
    </Card>
  ));
};

export default DefaultOption;
