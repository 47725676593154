import React from "react";

import { faPaperclip, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cx from "classnames";
import Classes from "./upload.module.scss";

const getFileName = (title = '') => {
  const count = title.length;
  if (count > 10) {
    return `${title.slice(0, 10)}...${title.slice(count -5, count)}`
  }

  return title;
}

export const UploadedList = ({ remove, rename, files = [] }) => (
  <div className={Classes.uploadedList}>
    {files.map((file) => (
      <div key={file.id} className={Classes.uploadedItem}>
        <span className={Classes.fileName}>{getFileName(file.title)}</span>
        <FontAwesomeIcon
          icon={faTrash}
          className={Classes.remove}
          onClick={() => remove(file.id)}
        />
      </div>
    ))}
  </div>
);

export const Upload = ({ add, forComponent = 'all', className = "" }) => (
  <div className={cx(Classes.upload, className)}>
    <label htmlFor={`file_${forComponent}`} className={Classes.uploadIcon}>
      <FontAwesomeIcon icon={faPaperclip} />
    </label>
    <input type="file" id={`file_${forComponent}`} multiple={false} accept="image/*" onChange={add} />
  </div>
);
