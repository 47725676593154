import Classes from "./LevelProgress.module.scss";
import { getPassedPercentage } from "../../helper";

const Progress = ({ passing_score = 0, score = 0 }) => (
    <div className={Classes.container}>
        <div className={Classes.score}>
           Score {score} / {passing_score}
        </div>
        <div className={`progress ${Classes.progress}`}>
            <div
                className={`progress-bar ${Classes.bar}`}
                style={{ width: `${getPassedPercentage(passing_score, score)}%` }}
                role="progressbar"
                aria-valuenow={score}
                aria-valuemin="0"
                aria-valuemax={passing_score}
            >
            </div>
        </div>
    </div>
);

export default Progress;