import actionTypes from "../actionTypes";

export function requestUpdateAccount(accountId = '', updateParams) {
  return {
    type: actionTypes.UPDATE_ACCOUNT_REQUEST,
    accountId,
    updateParams,
  };
}

export function updateAccountSuccess(authData) {
  return {
    type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
    authData,
  };
}

export function updateAccountFailure(e) {
  return {
    type: actionTypes.UPDATE_ACCOUNT_FAILURE,
    exception: e,
  };
}

export function resetPasswordRequest({ email, history }) {
  return {
    type: actionTypes.RESET_PASSWORD_REQUEST,
    email,
    history,
  };
}

export function resetPasswordSuccess() {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordFailure(e) {
  return {
    type: actionTypes.RESET_PASSWORD_FAILURE,
    exception: e,
  };
}