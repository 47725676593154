import React from "react";
import { isCorrectAnswer } from "components/Game/helper";
import { getContentByLanguage } from "helpers/util";

import { ANSWER_ORDER_NAME } from "components/Game/constants";

import cx from "classnames";
import Classes from "./Answers.module.scss";

const getAnswerClassName = (isTaken, isCorrect, isTakenAnswer) => {
  if (isTaken) {
    if (isCorrect === true) {
      // Mark if answered is correct
      return cx(Classes.answer, Classes.disabled, Classes.correct);
    }

    if (isTakenAnswer === true) {
      // If answer is not the correct then it is incorrect
      return cx(Classes.answer, Classes.disabled, Classes.incorrect);
    }
  }

  // Default answer styles
  return Classes.answer;
};

const Answer = ({ takenAnswer, answer, takeAnswer, index }) => {
  const answerText = getContentByLanguage(answer, "text");

  const className = getAnswerClassName(
    !!takenAnswer,
    isCorrectAnswer(takenAnswer, answer),
    takenAnswer?.quiz_answer_id === answer.quiz_answer_id
  );

  return (
    <div onClick={takeAnswer.bind(null, answer)} className={className}>
      <div className={Classes.mark}>{ANSWER_ORDER_NAME[index]}</div>
      {answer && answerText ? (
        <span className="mx-1">{answerText}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Answer;
