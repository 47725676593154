import actionTypes from "../actionTypes";

export const getCustomersRequest = () => ({
  type: actionTypes.GET_CUSTOMERS_REQUEST,
});

export const getCustomersFailure = (exception) => ({
  type: actionTypes.GET_CUSTOMERS_FAILURE,
  exception,
});

export const getCustomersSuccess = ({ customers, isAdmin }) => ({
  type: actionTypes.GET_CUSTOMERS_SUCCESS,
  customers,
  isAdmin,
});

export const setCurrentCustomer = (customer) => ({
  type: actionTypes.SET_CURRENT_CUSTOMER,
  customer,
});
