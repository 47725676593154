import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import cx from "classnames";
import Classes from './CloseButton.module.scss';

const CloseButton = ({ onClose, className = '' }) => (
  <div className={cx(Classes.closeIcon, className)} onClick={onClose}>
    <FontAwesomeIcon icon={faTimesCircle} />
  </div>
);

export default CloseButton;
