import { createSelector } from "reselect";

const authData = (state) => state?.authData;
const navigation = (state) => state.navigation;
const searchParams = (state) => state.searchParams;
const breadcrumb = (state) => state.breadcrumb;
const sidebar = (state) => state.sidebar;
const customers = (state) => state.customers;
const metrics = (state) => state.metrics;
const notification = (state) => state.notification;
const messages = (state) => state.messages;
const offlineMode = (state) => state.offlineMode;

export const getSideBarMenuState = sidebar;

export const getAuthenticationToken = createSelector(authData, (a) => a.token);

export const getAuthenticationStatus = createSelector(
  authData,
  (a) => a.authenticated
);

export const getForcedToChangePassword = createSelector(
  authData,
  (a) => a?.account?.needs_reset_password
);

export const getAccount = createSelector(authData, (a) => a?.account);

export const getCurrentScreen = createSelector(
  navigation,
  (a) => a.currentScreen
);

export const getUnreadMessagesCount = createSelector(messages, (msgs) =>
  msgs?.reduce((sum, thread) => (sum += thread.unreads), 0)
);

export const getSearchParams = searchParams;
export const getBreadcrumbList = breadcrumb;
export const getCustomersList = createSelector(customers, (a) => a.list);
export const getCurrentCustomer = createSelector(customers, (a) => a.selected);
export const getCurrentMetricsKey = metrics;
export const getNotification = notification;
export const getMessages = messages;
export const getParsedNavigation = createSelector(navigation, (a) => a.parsed);

export const getOfflineStatus = createSelector(offlineMode, (a) => a.offline);
export const getOfflineAppState = createSelector(
  offlineMode,
  (a) => a.available
);
