import React from "react";
import { useSelector } from "react-redux";
import { getBreadcrumbList } from "store/selectors";
import { Link } from "react-router-dom";
import Classes from "./Breadcrumb.module.scss";
import { getContentByLanguage } from "helpers/util";

const BreadcrumbNavigator = () => {
  const crumbs = useSelector(getBreadcrumbList);

  return (
    <nav aria-label="breadcrumb" className={Classes.container}>
      <ol className={`breadcrumb ${Classes.breadcrumb}`}>
        {crumbs.map((crumb, index) => {
          if (index === 0) {
            return "";
          }

          const isLastElement = crumbs.length - 1 === index;
          const isDottedElement = !(crumbs.length - 3 < index);
          const title = getContentByLanguage(crumb, "short_name", "name");

          return (
            <li
              className={`breadcrumb-item ${isLastElement ? "active" : ""}`}
              key={index}
            >
              {isDottedElement ? (
                <Link key={index} to={crumb.path}>
                  ...
                </Link>
              ) : (
                <React.Fragment>
                  {!isLastElement ? (
                    <Link to={crumb.path}>{title}</Link>
                  ) : (
                    title
                  )}
                </React.Fragment>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadcrumbNavigator;
