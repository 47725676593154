import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthentication } from "../store/actionCreators/authentication";
import { getAccount, getAuthenticationStatus } from "../store/selectors";
import { showFailureNotification } from "store/actionCreators/notification";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "config/constants";

import useNetworkStatus from "components/NetworkStatus/useNetworkStatus";
import useLogout from "hooks/useLogout";

import Public from "./Public";
import Private from "./Private";

import { apiUrl } from "config/api";

const Routes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getAuthenticationStatus);
  const account = useSelector(getAccount);
  const { i18n } = useTranslation();
  useLogout();
  useNetworkStatus();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      if (isAuthenticated === true && account) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage({
            account,
            authenticated: true,
            API_URL: apiUrl,
            APP_URL: process.env.REACT_APP_URL,
          });
        });
      } else if (isAuthenticated === false) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage({ authenticated: false });
        });
      }
    } else {
      dispatch(
        showFailureNotification(
          "Offline mode is not supported for this device!"
        )
      );
    }
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      if (isAuthenticated === true && account) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage({
            account,
            authenticated: true,
            API_URL: apiUrl,
            APP_URL: process.env.REACT_APP_URL,
          });
        });
      } else if (isAuthenticated === false) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage({ authenticated: false });
        });
      }
    } else {
      dispatch(
        showFailureNotification(
          "Offline mode is not supported for this device!"
        )
      );
    }
  }, [isAuthenticated, account, dispatch]);

  const authCheck = () => {
    dispatch(checkAuthentication());
  };

  // Check auth state on init
  useEffect(() => {
    authCheck();
    if (!localStorage.language) {
      localStorage.setItem("language", DEFAULT_LANGUAGE);
    }
    i18n.changeLanguage(localStorage.language);
  }, [dispatch]);

  return (
    <React.Fragment>
      {isAuthenticated === true && <Private />}
      {isAuthenticated === false && <Public />}
    </React.Fragment>
  );
};

export default Routes;
