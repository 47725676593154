import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Classes from "./Main/Main.module.scss";
import LoadingIndicator from "../common/LoadingIndicator";

const OnlyFooter = (props) => {
  const { children } = props;
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <div className={Classes.mainLayout}>
        <main className={Classes.pageContent}>{children}</main>
      </div>
    </Suspense>
  );
};

OnlyFooter.propTypes = {
  children: PropTypes.node,
};

export default OnlyFooter;
