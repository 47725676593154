import React from "react";
import PropTypes from "prop-types";

import useNavigation from "hooks/useNavigation";
import useURLParams from "hooks/useURLParams";

import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadcrumbNavigator from "components/BreadcrumbNavigator";
import HomeScreenPrompt from "components/HomeScreenPrompt";

import Classes from "./Main.module.scss";

const Main = ({ children, withBackButton }) => {
  // Parse navigation file to keep a plain hash for content entries
  useNavigation();
  useURLParams();

  return (
    <React.Fragment>
      <Header withBackButton={withBackButton} />
      <div className={Classes.pageContent}>
        <BreadcrumbNavigator />
        {children}
        {/* <Footer /> */}
        <HomeScreenPrompt />
      </div>
    </React.Fragment>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
