import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordRequest } from "store/actionCreators/account";

import { useHistory } from "react-router";
import Email from "components/common/Email";

import Classes from "./ResetPassword.module.scss";
import { showFailureNotification } from "store/actionCreators/notification";

const ResetPassword = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const resetPasswordError = useSelector((state) => state.authData.resetPasswordError);
  const [email, setEmail] = useState("");


  const resetPassword = (e) => {
    if (e.key === "Enter") resetPasswordUser();
  };

  const changeEmail = (e) => {
    const withoutSpaces = e.target.value.replace(/\s/g, ""),
      validator = new RegExp("^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$");
    setEmail(withoutSpaces);
  };

  const resetPasswordUser = () => {
    if (email.length > 0) {
      dispatch(resetPasswordRequest({ email, history }));
    } else {
      dispatch(showFailureNotification("The email field is empty"));
    }
  };

  useEffect(() => {

    if (resetPasswordError?.email) {
      dispatch(showFailureNotification(resetPasswordError.email));
    }

  }, [resetPasswordError, dispatch]);

  return (
    <div className={Classes.container} onKeyUp={resetPassword}>
      <div className={Classes.formContainer}>
        <label className={Classes.label}>Reset Password</label>
        <div className={Classes.form}>
          <Email change={changeEmail} email={email} />
          <footer>
            <button onClick={resetPasswordUser} className={Classes.submit} type="submit">
              Reset
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
