import { getApplyParams } from "helpers/util";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPlant } from "services/apiService";
import {
  addBreadcrumb,
  assignBreadcrumbs,
} from "store/actionCreators/breadcrumb";
import { changeScreen } from "store/actionCreators/navigation";
import { assignSearchParams } from "store/actionCreators/search";
import { getParsedNavigation } from "store/selectors";

const processNavigationParams = (pathname, parsedNavigation) => {
  console.log({pathname})
  const contentIds = pathname.split("/");
  console.log({contentIds})
  let searchParams = {};
  let currentScreen = "category";
  const crumbs = [
    {
      short_name_en: "Home",
      path: "/home",
    },
  ];
  contentIds.forEach((element, index) => {
    const content_id = Number(element);
    console.log({content_id})
    const navItem = parsedNavigation[content_id];
    console.log({navItem})
    if (!!navItem) {
      searchParams = {
        ...searchParams,
        ...getApplyParams(navItem.apply, content_id),
      };

      const currentContentIds = contentIds.slice(2, index + 1);
      crumbs.push({
        short_name_es: navItem.short_name_es,
        short_name_en: navItem.short_name_en,
        path: `/home/${currentContentIds.join("/")}`,
      });
      currentScreen = navItem.next;
    }
  });

  console.log({crumbs, currentScreen, searchParams})

  return { crumbs, currentScreen, searchParams };
};

export const useURLParams = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const parsedNavigation = useSelector(getParsedNavigation);

  console.log({search, pathname})

  const fetchPlant = async (search) => {
    const plantId = new URLSearchParams(search).get("plantId");
    console.log({plantId})
    if (plantId) {
      const plantResponse = await getPlant({ plant_id: Number(plantId) });
      const crumb = {
        short_name_es: plantResponse?.name_es,
        short_name_en: plantResponse?.name_en,
        path: "",
      };
      dispatch(addBreadcrumb(crumb));
    }
  };

  useEffect(() => {
    if (!pathname.includes("/search") && parsedNavigation) {
      const result = processNavigationParams(pathname, parsedNavigation);
      dispatch(changeScreen(result.currentScreen));
      dispatch(assignSearchParams(result.searchParams));
      dispatch(assignBreadcrumbs(result.crumbs));
      fetchPlant(search);
    }
  }, [search, pathname, parsedNavigation, dispatch]);

  return false;
};

export default useURLParams;
