export const ADMIN_ID = 1;

export const ACCOUNT_ROLES = {
  1: "Admin",
  2: "Member",
};

export const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "es",
    name: "Spanish",
  },
];

export const FEET_SYSTEM_KEY = "feetInches";
export const METRIC_SYSTEM_KEY = "metersCentimeters";

export const METRICS = {
  feetInches: {
    key: FEET_SYSTEM_KEY,
    name: "Feet / inches",
  },
  metersCentimeters: {
    key: METRIC_SYSTEM_KEY,
    name: "Meters / centimeters",
  },
};

export const DEFAULT_LANGUAGE = "en";

export const SIGN_IN_UP_ERROR_CODES = {
  nonExistentUsername: 1,
  incorrectPassword: 2,
  usernameTaken: 422,
  passwordContent: 4,
  validEmailNotProvided: 5,
  emailNotConnected: 6,
  emptyField: 7,
  usernameLength: 8,
  maxSymbols: 9,
  noSpaces: 10,
  acceptTermsAndConditions: 11,
};
