import React, { useEffect, useState } from "react";
import { getLeaderboard } from "services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAccount } from "store/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";
import Classes from "./LevelStatistics.module.scss";

const LevelStatistics = ({ level, customer }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const { t } = useTranslation();

  const [levelData, setLevelData] = useState(null);
  const [quiz, setQuiz] = useState(null);

  const fetchLeaderboard = async () => {
    if (level && customer) {
      const response = await getLeaderboard(
        {
          level,
          customer_id: customer.customer_id,
        },
        dispatch
      );

      setLevelData(response ? response.data : []);
      setQuiz(response ? response.quiz : null);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [level, customer]);

  if (!levelData) {
    return "";
  }

  if (!quiz || !levelData) {
    return "";
  }

  return (
    <table className={Classes.table}>
      <thead>
        <tr className={Classes.header}>
          <th className={Classes.name}>{t("name")}</th>
          <th className={Classes.score}>{t("score")}</th>
        </tr>
      </thead>
      <tbody>
        {levelData?.map((row, index) => (
          <tr key={index} className={Classes.row}>
            <td
              className={cx(
                Classes.name,
                row.member?.member_id === account?.member_id
                  ? Classes.currentAccount
                  : ""
              )}
            >
              <FontAwesomeIcon icon={faTrophy} />
              <span>{row.member?.name}</span>
            </td>
            <td className={Classes.score}>{row.score}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LevelStatistics;
