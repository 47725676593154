export const LEVEL_STATUSES = {
    done: 'done',
    inProgress: 'in_progress',
    submitted: 'submitted',
    failed: 'failed',
    new: 'new',
};

export const LEVELS = [1, 2, 3];

export const ANSWER_ORDER_NAME = ["A", "B", "C", "D"];

export const QUESTION_TYPES = {
    multi: "MULTI",
    binary: "BINARY",
};
