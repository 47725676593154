import { ANSWER_ORDER_NAME } from "components/Game/constants";

import cx from "classnames";
import Classes from "./Answers.module.scss";
import { isCorrectAnswer } from "components/Game/helper";
import { getContentByLanguage } from "helpers/util";

const getAnswerClassName = (isTaken, isCorrect, isTakenAnswer) => {
  if (isTaken) {
    if (isCorrect === true) {
      // Mark if answered is correct
      return cx(Classes.answer, Classes.disabled, Classes.correct);
    }

    if (isTakenAnswer === true) {
      // If answer is not the correct then it is incorrect
      return cx(Classes.answer, Classes.disabled, Classes.incorrect);
    }
  }

  // Default answer styles
  return Classes.answer;
};

const Answers = ({ answers = [], takenAnswer, question, onTakeAnswer }) => (
  <div className={Classes.container}>
    {answers.map((answer, index) => {
      const answerText = answer && getContentByLanguage(answer, "text");

      return (
        <div
          key={index}
          className={getAnswerClassName(
            !!takenAnswer,
            isCorrectAnswer(takenAnswer, answer),
            takenAnswer?.quiz_answer_id === answer.quiz_answer_id
          )}
          onClick={onTakeAnswer.bind(null, answer, question.quiz_question_id)}
        >
          <div className={Classes.mark}>{ANSWER_ORDER_NAME[index]}</div>
          {!!answer.images?.length && (
            <div>
              {answer.images[0].img_uri_lowRes && (
                <img
                  src={answer.images[0].img_uri_lowRes}
                  className={Classes.image}
                  alt={answer.images[0]?.title}
                />
              )}
            </div>
          )}
          {answerText && <div className={Classes.text}>{answerText}</div>}
        </div>
      );
    })}
  </div>
);

export default Answers;
