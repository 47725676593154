import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getQuiz, getQuizTakenByLevel } from 'services/apiService';
import { getAccount, getCurrentCustomer } from 'store/selectors';

const QuizStateContext = createContext();
const QuizDispatchContext = createContext();

const initialState = {
  questionMoreInfo: null,
  linkedPlant: null,
  showLeaderboard: false,
  showFeedback: false,
  // loading: true,
  // waiting: false,
};

export const QuizProvider = ({ children }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const customer = useSelector(getCurrentCustomer);
  const { level } = useParams();
  const [quizState, setQuizState] = useState(initialState);

  useEffect(() => {
    setQuizState(current => ({
      ...current,
      level: Number(level),
    }));
  }, [level]);

  useEffect(() => {
    const fetchQuiz = async () => {
      if (!customer?.customer_id || !level) {
        return setQuizState(current => ({ ...current, quiz: null }));
      }

      const response = await getQuiz(level, customer?.customer_id, dispatch);
      setQuizState(current => ({ ...current, quiz: response }));
    };

    setQuizState(current => ({ ...current, quiz: null }));
    fetchQuiz();
  }, [level, customer?.customer_id, dispatch]);

  const fetchQuizTaken = useCallback(async () => {
    if (!customer?.customer_id || !quizState.quiz) {
      setQuizState(current => ({ ...current, quizTaken: null }));
      return;
    }

    const params = {
      level: Number(quizState.quiz.level),
      customer_id: Number(customer?.customer_id),
      member_id: account.member_id,
    };

    const response = await getQuizTakenByLevel(params, dispatch);
    setQuizState(current => ({ ...current, quizTaken: response }));
  }, [account.member_id, customer?.customer_id, dispatch, quizState.quiz]);

  useEffect(() => fetchQuizTaken(), [fetchQuizTaken]);

  const dispatchMoreInfo = (questionMoreInfo = null, linkedPlant = null) => setQuizState(current => ({
    ...current,
    linkedPlant,
    questionMoreInfo,
  }));

  const dispatchLeaderboard = (isShow) => setQuizState(current => ({
    ...current,
    showLeaderboard: isShow,
  }));

  const dispatchFeedback = (isShow) => setQuizState(current => ({
    ...current,
    showFeedback: isShow,
  }));

  const stateInitialize = () => setQuizState({
    ...initialState,
    level,
  });

  const stateDispatch = {
    dispatchMoreInfo,
    dispatchLeaderboard,
    dispatchFeedback,
    stateInitialize,
    fetchQuizTaken,
  };

  return (
    <QuizStateContext.Provider value={quizState}>
      <QuizDispatchContext.Provider value={stateDispatch}>
        {children}
      </QuizDispatchContext.Provider>
    </QuizStateContext.Provider>
  );
};

export const useQuizState = () => useContext(QuizStateContext);
export const useQuizDispatch = () => useContext(QuizDispatchContext);
export const useQuiz = () => [useQuizState(), useQuizDispatch()];
