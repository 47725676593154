import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import config from "../../../config/config";

import { readMessageThread, getConversation } from "services/apiService";
import { getAccount, getOfflineStatus } from "store/selectors";

import ConversationItem from "./Item";
import ReplyMessage from "./ReplyMessage";
import Avatar from "components/common/Avatar";
import LoadingIndicator from "components/common/LoadingIndicator";

import Classes from "./Conversation.module.scss";

const Conversation = () => {
  const offline = useSelector(getOfflineStatus);
  const { member_id } = useSelector(getAccount);
  const { partner_id } = useParams();

  const [messages, setMessages] = useState([]);
  const [partner, setPartner] = useState(null);

  const messagesEndRef = React.createRef(null);

  const fetchConversation = useCallback(async () => {
    if (member_id && partner_id && !offline) {
      const result = await getConversation(member_id, Number(partner_id));
      setMessages(result?.messages || []);
      setPartner(result?.partner);
    }
  }, [member_id, partner_id, offline]);

  useEffect(() => {
    const intervalId = setInterval(
      fetchConversation, config.fetchIntervals.messages
    );

    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    setMessages([]);
    fetchConversation();
  }, [partner_id, member_id, fetchConversation]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(scrollToBottom, [messagesEndRef]);

  useEffect(() => {
    readMessageThread({ to: member_id, from: Number(partner_id) });
  }, []);

  const addMessage = (data) => {
    setMessages(current => [...current, data]);
  }

  if (!partner || !member_id || !partner_id) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className={Classes.container}>
        {!!partner && (
          <div className={Classes.header}>
            <div>
              <Avatar name={partner.name} className={Classes.avatar} />
            </div>
            <div className={Classes.name}>{partner.name}</div>
          </div>
        )}
        <div className={Classes.messages}>
          {messages.map((msg) => (
            <ConversationItem
              key={msg.message_id}
              message={msg}
              member_id={member_id}
            />
          ))}
          {!messages.length && <div className={Classes.empty}>Empty</div>}
          <div ref={messagesEndRef} />
        </div>
        <ReplyMessage receiverId={partner_id} senderId={member_id} addMessage={addMessage} />
      </div>
    </>
  );
};

export default Conversation;
