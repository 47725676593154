import React from "react";
import { Spinner } from "react-bootstrap";
import Classes from "./LoadingIndicator.module.scss";

export default function LoadingIndicator() {
  return (
    <div className={Classes.loadingContainer}>
      <div className={Classes.screenCenter}>
        <Spinner animation="border" role="status" style={{color: "var(--primaryColor)"}}>
          {/* <span className="visually-hidden">Loading...</span> */}
        </Spinner>
      </div>
    </div>
  );
}
