import { take, fork, put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  failLogin,
  successLogin,
  logout,
} from "../actionCreators/authentication";
import v from "../../validators/GlobalValidator";
import { callFetchApi } from "./callApi";
import { saveUser, clearToken } from "helpers/localStorage";

function* makeLogin({ email, password, token }) {
  try {
    if (password) {
      let validation = v.validateAuthentication({ email, password });
      if (!validation.success) {
        const messages = Object.values(validation.error).join("\n");
        return yield put(failLogin({ loginError: messages }));
      }
    }
    const params = { email };
    if (!!token) {
      params.token = token;
    } else {
      params.password = password;
    }
    const response = yield call(callFetchApi, `authenticate`, "POST", params);
    if (!response.success) {
      return yield put(failLogin({ loginError: response.message }));
    }

    yield put(successLogin({ authenticated: true, ...response }));
    saveUser(response);
    window.isAuthenticated = true;
  } catch (e) {
    if (!!e && !!e.response && !!e.response.data) {
      yield put(failLogin({ loginError: e.response.data }));
    }
  }
}

function* checkAuthentication() {
  console.log("checkAuthentication: SAGA");
  try {
    let authData =
        localStorage.authenticationData &&
        JSON.parse(localStorage.authenticationData),
      currentTime = new Date().getTime() / 1000;

    if (authData && authData.token) {
      //   if (currentTime < localStorage.expDate) {
      yield put(successLogin({ authenticated: true, ...authData }));
      //   } else {
      // console.log("token is expired, logging out..");
      // yield put(logout());
      //   }
    } else {
      yield put(logout());
    }
  } catch (e) {
    yield put(failLogin({ loginError: e }));
  }
}

export function* watchLogin() {
  while (true) {
    const action = yield take(actionTypes.LOGIN_REQUEST);
    yield fork(makeLogin, action.value);
  }
}

export function* watchLogout() {
  while (true) {
    yield take(actionTypes.USER_LOGOUT);
    clearToken();
  }
}

export function* watchCheckAuthentication() {
  while (true) {
    yield take(actionTypes.CHECK_AUTHENTICATION);
    yield fork(checkAuthentication);
  }
}
